import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import Paginacion from '../componentes/Paginacion'
import obtenerDatos from '../librerias/obtenerDatos'
import obtenerData from '../librerias/obtenerData'
import Busqueda from '../componentes/Busqueda'
import Titulo from '../componentes/Titulo'
import API from '../librerias/api';
import funciones from '../librerias/funciones';
import obtenerPermisos from '../librerias/obtenerPermisos';
import icono from './img/icono-busqueda.png';
import swal from 'sweetalert'
import object from './Usuarios/perfil.png';

const Dashboard = () => {
    const POLIZASURL = 'auth/dashboard/dashboard'
    const POLIZAELIMINAR = '/destroy'
    const { cargando, data: polizas, paginas, cambiarPagina, buscarData, current } = obtenerDatos(POLIZASURL);
    const permisos = obtenerPermisos("modulo-dashboard");
    const datos = obtenerData('auth/dashboard/datos-superiores/', 0, 1);


    const eliminar = async (id) => {

        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
        })
        if (respuesta) {
            try {
                const response = await API.delete(POLIZASURL + '/' + id + POLIZAELIMINAR);
                if (response.status === 200) {
                    swal("Poliza elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page => page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error, document.getElementById("respuesta"));
            }

        }

    }
    const primerosDOsServicios = (equipos) => {
        var BreakException = {};
        var numeroServicios = 0;
        var htmlImprimir = "";
        var limiteServicios = 1;//2
        equipos.forEach((element) => {
            //console.log(element)
            element.servicio.forEach((elementoserv) => {
                if (numeroServicios > limiteServicios) {

                } else if(elementoserv.servicio) {
                // } else {
                        numeroServicios++;
                        htmlImprimir += elementoserv.servicio.Nombre + " - "

                }

            });
        });
        // console.log(htmlImprimir)
        return htmlImprimir.slice(0, -3) + "...";
    }
    const errorImg = e => {
        e.target.src = object;
    }
    return (
        <>
            <Titulo titulo="Dashboard" tituloBajo=""></Titulo>
            <div style={{ "display": "flex", "flex-direction": "row", "flex-wrap": "wrap", "justify-content": "space-between", "text-align": "center", "margin-bottom": "20px", "margin-top": "-25px", "max-width": "1000px", "width": "90%", "margin": "-25px auto 20px auto" }}>
                <div style={{ "margin-left": "10px" }}>
                    <h2 style={{ "font-size": "35px", "color": "#fff" }}><b>{datos.clientes}</b></h2>
                    <h3 style={{ "font-size": "16px", "color": "#fff" }}>Clientes registrados</h3>
                </div>
                <div style={{ "height": "40px", "width": "2px", "background": "#810000" }}></div>
                <div>
                    <h2 style={{ "font-size": "35px", "color": "#fff" }}><b>{datos.polizas}</b></h2>
                    <h3 style={{ "font-size": "16px", "color": "#fff" }}>Pólizas activas</h3>
                </div>
                <div style={{ "height": "40px", "width": "2px", "background": "#810000" }}></div>
                <div>
                    <h2 style={{ "font-size": "35px", "color": "#fff" }}><b>{datos.equipos}</b></h2>
                    <h3 style={{ "font-size": "16px", "color": "#fff" }}>Equipos registrados</h3>
                </div>
                <div style={{ "height": "40px", "width": "2px", "background": "#810000" }}></div>
                <div style={{ "margin-right": "10px" }}>
                    <h2 style={{ "font-size": "35px", "color": "#fff" }}><b>{datos.proximasPolizas}</b></h2>
                    <h3 style={{ "font-size": "16px", "color": "#fff" }}>Siguientes citas prox 5 días</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">
                        <div className="pb-3">
                            <div className="table-responsive" style={{ borderTopLeftRadius: "5px" }}>
                                {cargando ?
                                    <div className="text-center pt-5">
                                        <h3 style={{ color: "black" }}>Cargando...</h3>
                                    </div>
                                    :
                                    <>
                                        {
                                            polizas.length > 0 ?
                                                <>
                                                    <table className="table mb-0 table-striped text-center">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col"></th>
                                                                <th scope="col">Cliente</th>
                                                                <th scope="col">Servicios</th>
                                                                <th scope="col">Fecha</th>
                                                                <th scope="col">Equipo(s)</th>
                                                                <th scope="col">Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                polizas.map((object, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {<img src={object.cliente.img} onError={errorImg} id="img-carga" alt="Avatar" style={{ "height": "65px", "margin-right": "10px", "width": "35px", "height": "35px" }} />}
                                                                        </td>
                                                                        <td>
                                                                            {object.cliente.Empresa}
                                                                        </td>
                                                                        <td>{primerosDOsServicios(object.equipo)}</td>
                                                                        <td>{object.fechaServicio}</td>
                                                                        <td>
                                                                            <ul>
                                                                                {
                                                                                    object.equipo.map((objeto, key) => (
                                                                                        <li key={key} >&#x2022;{objeto.equipo.Nombre}</li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                permisos.Actualizar === 1 ?
                                                                                    <Link className="" name="id" to={`/editar-poliza/${object.id}/`}><i className="demo-icon icon-editar color-tecno">&#xe804;</i></Link>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                                                </>
                                                :
                                                <div className="text-center pt-5">
                                                    <h3>Sin resultados...</h3>
                                                </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard;