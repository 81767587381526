import React, {useEffect} from 'react';
import {Link} from 'react-router-dom'
import Paginacion from '../../componentes/Paginacion'
import obtenerDatos from '../../librerias/obtenerDatos'
import Busqueda from '../../componentes/Busqueda'
import Titulo from '../../componentes/Titulo'
import API from '../../librerias/api';
import funciones from '../../librerias/funciones';
import obtenerPermisos from '../../librerias/obtenerPermisos';
import icono from '../img/icono-busqueda.png'; 
import swal from 'sweetalert'


const Roles =() => {
    const URLROLES= 'auth/admin/roles'
    const URLROLELIMINAR = '/destroy'
    const { cargando,data:roles, paginas,cambiarPagina, buscarData, current  } =obtenerDatos(URLROLES);
    const permisos  =obtenerPermisos("modulo-roles");
console.log(permisos.Actualizar)


    const eliminar = async (id)=>{

        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await  swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          if(respuesta){
              try {
                const response =  await API.delete(URLROLES+"/"+id+URLROLELIMINAR);
                if(response.status===200){
                    swal("Registro elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page=>page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error,document.getElementById("respuesta"));
            }

          }

    }

    return (
    <>
    {/* <div className='row'>
        <div className='cuadro col-12'></div>
    </div> */}
    <Titulo  titulo="Roles" tituloBajo=""></Titulo>
        <div className="row">
        {/* Modal prueba */}
        {/* <button type="button" className="btn btn-primary mb-3 ml-3" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Modal prueba</button> */}
        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="widget-header bordered no-actions d-flex align-items-center">
                            <h3 className="pl-2 linea">Roles registrados</h3>
                        </div>

                        
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                        <div className="form-group">
                            <label for="recipient-name" className="col-form-label titulo-modal">Paso 1: Selecciona el cliente y porteriormente define el producto que deseas asociarle</label>
                            <label for="recipient-name" className="col-form-label">Cliente:</label>
                            <input type="text" className="form-control" id="recipient-name"></input>
                        </div>
                        <div className="form-group">
                            <label for="recipient-name" className="col-form-label titulo-modal">Paso 2: Asocia el producto que posee el cliente</label> <br></br>
                            <div className="row">
                                <div className="form-group col-3">
                                    <p className="mb-1">Producto</p>
                                    <select name="Recursos" id="Recursos" className="form-control mb-3" required="">
                                    <option value="">- Seleccionar -</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <p className="mb-1">Marca</p>
                                    <select name="Recursos" id="Recursos" className="form-control mb-3" required="">
                                    <option value="">- Seleccionar -</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <p className="mb-1">Modelo</p>
                                    <select name="Recursos" id="Recursos" className="form-control mb-3" required="">
                                    <option value="">- Seleccionar -</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <p className="mb-1">Modelo</p>
                                    <select name="Recursos" id="Recursos" className="form-control mb-3" required="">
                                    <option value="">- Seleccionar -</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button type="button" className="btn btn-primary">Enviar</button>
                    </div>
                </div>
            </div>
        </div>
        {/* Cierra modal prueba */}
        <div className="col-xl-12 pb-5">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-2 linea">Roles registrados</h3>
                    <Busqueda buscarData={buscarData} icono={icono} permiso={permisos} ruta="/AgregarRoles" ></Busqueda>
                </div>
                <div className="pb-3">
                    <div className="table-responsive">
                    { cargando ?
                        <div className="text-center pt-5">
                            <h3 style={{color: "black"}}>Cargando...</h3>
                        </div>
                        :
                        <>
                        {
                            roles.length >0 ?
                            <>
                            <table className="table mb-0 table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Fecha Creación</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    roles.map((object, index) => (
                                    <tr key={index}>
                                        <td>{ object.Nombre }</td>
                                        <td>{ object.Descripcion }</td>
                                        <td>{ object.created_at }</td>
                                        <td>
                                            {
                                                permisos.Actualizar ===1  ?
                                                <Link className="" name="id" to={`/editar-rol/${object.id}`}><i className="demo-icon icon-editar color-tecno">&#xe804;</i></Link>
                                                :
                                                ""
                                                }
                                                {
                                                permisos.Visualizar ===1  ?
                                                <Link className="ml-3" name="id" to={`/ver-rol/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                                :
                                                ""
                                                }
                                                {
                                                permisos.Eliminar ===1  ?
                                                <Link className="ml-3" to="#" onClick={()=>eliminar(object.id)}><i className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link> 
                                                :
                                                ""
                                            }
                                            
                                            
                                        </td>
                                    </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                            </>
                            :
                            <div className="text-center pt-5">
                                <h3>Sin resultados...</h3>
                            </div> 
                        }
                        </>
                    }
                    </div>
                </div>
                <div id="respuesta" ></div>
            </div>
        </div>
    </div>
    </>

    // <div className="row">
    //     <div className="col-xl-12">
    //         <div className="widget has-shadow"></div>
    // </div>


    )
}

export default Roles;