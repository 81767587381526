import React, {useState,useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import ModalTablaEquipos from '../../../componentes/ModalTablaCliente'
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import obtenerCatalogoSinDefecto from './../../../librerias/obtenerCatalogoSinDefecto'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


const EditarServicio =() => {
    let { id,idservicio } = useParams();
    const URLSERVICIOLEER = 'auth/servicio/serviciosp-mostrar/'
    const servicio  =obtenerData(URLSERVICIOLEER,id+"/"+idservicio,1)
    const { register, handleSubmit, errors,setValue  } = useForm()
    const [cliente,setCliente] = useState({Empresa:""});
    const [fechaServicio, setFechaServicio] = useState(new Date());
    const [productosservicios,setProductosservicios] = useState([])
    const [refacciones,setRefacciones] = useState([])

    useEffect(
        ()=>{
        const asignar =(servicio) =>{
            if(servicio.id){
                if(id!=0){
                    setCliente(servicio.cliente)
                }else{
                    setCliente(servicio.cliente)
                    setProductosservicios(servicio.equipo);
                    
                }
                // setValue('Nombre', poliza.Nombre)
                // setValue('Descripcion', poliza.Descripcion)
                // traerServicios();
                // var equipoArray = [];
                // var equipoIndice = 0;
                // if(poliza.equipo)
 
                // setProductosservicios(equipoArray);
                // setCliente({id:poliza.cliente.id,nombre:poliza.cliente.Empresa})
                // traerProductos(poliza.cliente.id)
                // setValue('Periodo', poliza.Periodo)
                // setValue('Nota', poliza.Nota)
                // setFechaServicio(new Date(poliza.fechaServicio.replace(/-/g, '\/')))
            }
        }
        asignar(servicio)
        }
    ,[setValue,servicio]);
    
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            let formData = new FormData(document.getElementById("form-poliza"));
            // productosservicios.forEach((element)=>(formData.append("productos[]", JSON.stringify(element))))
            const response =  await API.post('auth/servicio/serviciosp-actualizar/'+servicio.id, formData);
            if(response.status===200){
               swal({
                    title: "Actualizado",
                    text: "El servicio se ha actualizado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/polizas')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
              console.log(error)
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            document.getElementById("enviar").disabled = false;
          }
       }
    const agregarRefaccion = () => {
        var cantidad = document.getElementById("cantidad").value
        var descripcion = document.getElementById("descripcion").value
        var precioUnitario = document.getElementById("precioUnitario").value
        // validamos valores decimales
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (!RE.test(cantidad)) {
        alert(1);
            return true;
        } 
        if (!RE.test(precioUnitario)) {
        alert(2);

            return true;
        } 




        const clsRefaccion = {
            cantidad: cantidad,
            descripcion: descripcion,
            precioUnitario: precioUnitario,
            total: cantidad*precioUnitario,
        };
        setRefacciones(refacciones => [...refacciones, clsRefaccion]);
    };
    return (
    <>
    <Titulo titulo="Servicios" tituloBajo="Servicios &gt; Editar"></Titulo>
    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Servicios</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                <form onSubmit={handleSubmit(onSubmit)} id="form-poliza">
                        <h3>
                            Cliente
                            <br />
                        </h3>
                        <div className="form-group form-row">
                            
                            <div className="col-6" >
                                <label htmlFor="fechaServicio">Fecha*</label>
                                <DatePicker
                                            selected={fechaServicio}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            name="fechaServicio"
                                            onChange={date=>setFechaServicio(date)}
                                                />       
                            </div>
                            <div className="col-6" >
                                <label htmlFor="catsucursal_id">Cliente/Empresa*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" readOnly={true} value={cliente.Empresa} name="cliente" id="cliente"/>
                                </div>
                            </div>
                        </div>
                        {
                          cliente.id?
                          <>
                            <div className="form-group form-row">
                                <div className="col-6" >
                                    <label htmlFor="Contacto">Contacto*</label>
                                    <div className="input-group">
                                    <label htmlFor="Contacto" className="form-control">{cliente.Contacto}</label>
                                    </div>
                                </div>
                                <div className="col-6" >
                                    <label htmlFor="Direccion">Dirección*</label>
                                    <div className="input-group">
                                        <label htmlFor="Direccion" className="form-control">{cliente.Direccion}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group form-row">
                                <div className="col-6" >
                                    <label htmlFor="Ciudad">Ciudad*</label>
                                    <div className="input-group">
                                        <label htmlFor="Ciudad" className="form-control">{cliente.Ciudad}</label>
                                    </div>
                                </div>
                                <div className="col-6" >
                                    <label htmlFor="Estado">Estado*</label>
                                    <div className="input-group">
                                        <label htmlFor="Estado" className="form-control">{cliente.Estado}</label>
                                    </div>
                                </div>
                            </div>
                          </>  
                          :
                          "Sin cliente"
                        }
                        <h3>
                            Equipo revisado
                            <br />
                        </h3>
                        {

                            productosservicios ?
                            productosservicios.map((objeto,index)=> {
                                // var texto = objeto.fecha ? "-"+objeto.fecha :"";
                                // texto =`${objeto.valor} (${objeto.servicios.length})  ${texto}`; 
                                console.log(objeto)
                            return ( 
                                <div className="form-group form-row">
                                    <div className="col-3" >
                                        <label htmlFor="Marca">Marca</label>
                                        <div className="input-group">
                                        <label htmlFor="Marca" className="form-control">{objeto.equipo.marca.Nombre}</label>
                                        </div>
                                    </div>
                                    <div className="col-3" >
                                        <label htmlFor="Modelo">Modelo*</label>
                                        <div className="input-group">
                                            <label htmlFor="Modelo" className="form-control">{objeto.equipo.Modelo}</label>
                                        </div>
                                    </div>
                                    <div className="col-3" >
                                        <label htmlFor="Serie">Serie*</label>
                                        <div className="input-group">
                                            <label htmlFor="Serie" className="form-control">{objeto.equipo.Serie}</label>
                                        </div>
                                    </div>
                                    <div className="col-3" >
                                        <label htmlFor="Capacidad">Capacidad*</label>
                                        <div className="input-group">
                                            <label htmlFor="Capacidad" className="form-control">{objeto.equipo.Capacidad}</label>
                                        </div>
                                    </div>
                                </div>
                                )
                            })

                            :
                            "Sin equipos"
                        }
                        <h3>
                            Refacciones
                            <br />
                        </h3>
                        <div className="form-group form-row">
                            <div className="col-3" >
                                <label htmlFor="Cantidad">Cantidad</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="cantidad" id="cantidad"/>
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="Descripcion">Descripción*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="descripcion" id="descripcion"/>
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="Precio">Precio Unitario*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="precioUnitario" id="precioUnitario"/>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="button" onClick={agregarRefaccion} >Agregar</button>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-3" >
                                <label htmlFor="Marca">cantidad</label>
                                <div className="input-group">
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="Marca">descripcion</label>
                                <div className="input-group">
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="precioUnitario">Precio unitario</label>
                                <div className="input-group">
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="total">total</label>
                                <div className="input-group">
                                </div>
                            </div>
                        </div>
                        {

                                refacciones.map((objeto,index)=> {
                                    console.log(objeto)
                                return ( 
                                    <div className="form-group form-row">
                                        <div className="col-3" >
                                            <div className="input-group">
                                            <label htmlFor="cantidad" className="form-control">{objeto.cantidad}</label>
                                            </div>
                                        </div>
                                        <div className="col-3" >
                                            <div className="input-group">
                                                <label htmlFor="descripcion" className="form-control">{objeto.descripcion}</label>
                                            </div>
                                        </div>
                                        <div className="col-3" >
                                            <div className="input-group">
                                                <label htmlFor="precioUnitario" className="form-control">{objeto.precioUnitario}</label>
                                            </div>
                                        </div>
                                        <div className="col-3" >
                                            <div className="input-group">
                                                <label htmlFor="total" className="form-control">{objeto.total}</label>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })

                        }

                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/polizas" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarServicio;