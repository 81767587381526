import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import ModalTablaCliente from '../../../componentes/ModalTablaCliente'
import ModalTablaPolizas from '../../../componentes/ModalTablaPolizas'
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import obtenerCatalogoSinDefecto from './../../../librerias/obtenerCatalogoSinDefecto'

const AgregarOrden =() => {
    //let { id,idservicio } = useParams();  //es para recibir variables por get /var1/var2
    const { register, handleSubmit, errors,setValue  } = useForm()
    const [cliente,setCliente] = useState({id:null, nombre:null, contacto: null, direccion: null, ciudad: null, estado: null});
    const [poliza,setPoliza] = useState({id:null, folio:null});
    const [fechaServicio, setFechaServicio] = useState(new Date());
    const [productosservicios,setProductosservicios] = useState([])
    const [refacciones,setRefacciones] = useState([])

    
    const [productos,setProductos] = useState({})
    const [producto,setProducto] = useState([])
    const [servicios,setServicios] = useState({})
    
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            let formData = new FormData(document.getElementById("form-orden"));
            refacciones.forEach((element)=>(formData.append("refacciones[]", JSON.stringify(element))))
            producto.forEach((element)=>(formData.append("productos[]", element.id)))
            formData.append("cliente_id", cliente.id)
            const response =  await API.post('auth/reportes/ordenes-agregar', formData);
            console.log(response)
            if(response.status===201){
                console.log(response)
               swal({
                    title: "Actualizado",
                    text: "El servicio se ha actualizado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/ordenes')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
        } catch (error) {
            console.log(error)
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            document.getElementById("enviar").disabled = false;
        }
    }
       
    const removerProducto = indexx => () => {
        setProductosservicios(productose => [...productose.filter((producto, index) =>     (   indexx !==index     ))]);
        setProducto(productose2 => [...productose2.filter((producto, index) =>     (   indexx !==index     ))]);
    };
    const agregarRefaccion = () => {
        var cantidad = document.getElementById("cantidad").value
        var descripcion = document.getElementById("descripcion").value
        var precioUnitario = document.getElementById("precioUnitario").value
        // validamos valores decimales
        var RE = /^\d*(\.\d{1})?\d{0,1}$/;
        if (!RE.test(cantidad)) {
        alert(1);
            return true;
        } 
        if (!RE.test(precioUnitario)) {
        alert(2);

            return true;
        }
        if(cantidad.length<1 && descripcion.length<1 && precioUnitario.length<1){
            alert('Debes llenar los campos "cantidad", "descripcion" y "precioUnitario".');
            return true;
        }




        const clsRefaccion = {
            cantidad: cantidad,
            descripcion: descripcion,
            precioUnitario: precioUnitario,
            total: cantidad*precioUnitario,
        };
        setRefacciones(refacciones => [...refacciones, clsRefaccion]);
        document.getElementById("cantidad").value = '';
        document.getElementById("descripcion").value = '';
        document.getElementById("precioUnitario").value = '';
    };
    
    const paginaCliente = ()=>{
        window.open("/?ruta=cliente")
    }
    useEffect(
        ()=>{
            traerServicios();
        }
        ,[]);
    
    const cambiar =(cliente)=>{
        setPoliza({id:null, folio: ''})
        setCliente({ id: cliente.id, nombre: cliente.Empresa, contacto: cliente.Contacto, direccion: cliente.Direccion, ciudad: cliente.Ciudad, estado: cliente.Estado });
        traerProductos( cliente.id);
        setProducto([])
        setProductosservicios([]);
        [...document.getElementsByClassName("check-servicios")].forEach(
            (element, index, array) => {
               element.checked = false;
            }
        );
    }
    const cambiar2 =(poliza)=>{
        setPoliza({id: poliza.id, folio: poliza.folio});
        setCliente({ id: poliza.cliente_id, nombre: poliza.Empresa, contacto: poliza.Contacto, direccion: poliza.Direccion, ciudad: poliza.Ciudad, estado: poliza.Estado });
        traerProductos( poliza.cliente_id);
        setProducto([])
        setProductosservicios([])
        poliza.equipo.forEach(function(element, indice, arra){
            var productoTemp = {id: element.equipo.id, modelo: element.equipo.Modelo, marca: element.equipo.marca.Nombre, serie: element.equipo.Serie, capacidad: element.equipo.Capacidad,};
            if(productoTemp){
                setProducto((productose2) => [...productose2, productoTemp]);
            }else{
                setProducto((productose2) => [...productose2, {}]);
            }
            const clsProducto = {
                producto_id: element.equipo.id,
                valor: element.equipo.Nombre
            };
            setProductosservicios(productose => [...productose, clsProducto]);
        });
        [...document.getElementsByClassName("check-servicios")].forEach(
            (element, index, array) => {
               element.checked = false;
            }
        );
        poliza.equipo[0].servicio.forEach(function(element, indice, arra){
            document.getElementById('serviciciocheck'+element.servicio_id).checked = true;
        });
    }
    
    const traerProductos =async (idCliente) => {
        var productos= await obtenerCatalogo('auth/catalogos/equipos-catalogo-cliente/'+idCliente)
        if(productos){
            setProductos(productos);
        }else{
            setProductos({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerProducto =async (idProducto) => {
        var response =   await API.get('auth/catalogos/equipos-mostrar/'+idProducto)
        var producto2 = await response.data
        var response2 =   await API.get('auth/catalogos/marcas-mostrar/'+producto2.marca_id)
        var marca = await response2.data
        var productoTemp = {id: producto2.id, modelo: producto2.Modelo, marca: marca.Nombre, serie: producto2.Serie, capacidad: producto2.Capacidad,};
        if(productoTemp){
            setProducto((productose2) => [...productose2, productoTemp]);
        }else{
            setProducto((productose2) => [...productose2, {}]);
        }
        
    }
    const agregarProducto = () => {
        var selectProducto = document.getElementById("producto")
        var idProducto =selectProducto.value
        if(idProducto=="")
        return false;
        var valorProducto = selectProducto.options[selectProducto.selectedIndex].text;
        // revisamos si ya existe
        var found = productosservicios.find(pro =>{
            return pro.producto_id==idProducto 
        } );
        // se encontro
        if(found){
            alert("Producto repetido")
            return false;
        }
        var arregloServicios = [];
        [...document.getElementsByClassName("check-servicios")].forEach(
            (element, index, array) => {
               if(element.checked){
                arregloServicios.push(element.value);
               }
            }
        );
        if(arregloServicios.length==0){
            alert("Favor de seleccionar un servicio")
            return false;
        }
        traerProducto(idProducto);
        const clsProducto = {
            producto_id: idProducto,
            valor: valorProducto
        };
        setProductosservicios(productose => [...productose, clsProducto]);
    };
    const traerServicios =async () => {
        var servicios= await obtenerCatalogoSinDefecto('auth/catalogos/servicios-catalogo')
        if(servicios){
            setServicios(servicios);
        }else{
            setServicios({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    return (
    <>
    <Titulo titulo="Nueva orden de servicio" tituloBajo="Ordenes de servicio &gt; Nueva orden de servicio"></Titulo>
    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Nueva orden de servicio</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                <form onSubmit={handleSubmit(onSubmit)} id="form-orden">
                    <p>Puede traer los datos seleccionando una póliza o un cliente</p>
                    <div className="form-group form-row">
                        <div className="col-6" >
                            <label htmlFor="polizas">Póliza*</label>
                            <div className="input-group">
                                <input type="text" className="form-control" readOnly={true} value={ poliza.folio } name="poliza" id="poliza"/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#modalPoliza" type="button">Buscar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                        <h3>
                            Cliente
                            <br />
                        </h3>
                    <div className="form-group form-row">
                        <div className="col-6" >
                            <label htmlFor="catsucursal_id">Cliente/Empresa*</label>
                            <div className="input-group">
                                <input type="text" className="form-control" readOnly={true} value={ cliente.nombre } name="cliente" id="cliente"/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#modalCliente" type="button">Buscar</button>
                                </div>
                            </div>
                        </div>
                        <div className="col" >
                            <label htmlFor="">&nbsp;</label>
                            <div >
                                |&nbsp;<button target="_blank"className="btn btn-decondary " onClick={paginaCliente}  type="button">Agregar cliente</button>
                            </div>
                        </div>
                    </div>

                        <div className="form-group form-row">
                            <div className="col-4" >
                                <label htmlFor="fechaServicio">Fecha*</label>
                                <DatePicker
                                            selected={fechaServicio}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            name="fecha_servicio"
                                            onChange={date=>setFechaServicio(date)}
                                                />       
                            </div>
                            <div className="col-4" >
                                <label htmlFor="Contacto">Contacto*</label>
                                <div className="input-group">
                                <input type="text" name="contacto_actual" className="form-control"/>
                                </div>
                            </div>
                            <div className="col-4" >
                                <label htmlFor="Departamento">Departamento de contacto</label>
                                <div className="input-group">
                                <input type="text" name="departamento" className="form-control"/>
                                </div>
                            </div>
                        </div>
                        {
                          cliente.id?
                          <>
                            <div className="form-group form-row">
                                <div className="col-4" >
                                    <label htmlFor="Direccion">Dirección*</label>
                                    <div className="input-group">
                                        <label htmlFor="Direccion" className="form-control">{cliente.direccion}</label>
                                    </div>
                                </div>
                                <div className="col-4" >
                                    <label htmlFor="Ciudad">Ciudad*</label>
                                    <div className="input-group">
                                        <label htmlFor="Ciudad" className="form-control">{cliente.ciudad}</label>
                                    </div>
                                </div>
                                <div className="col-4" >
                                    <label htmlFor="Estado">Estado*</label>
                                    <div className="input-group">
                                        <label htmlFor="Estado" className="form-control">{cliente.estado}</label>
                                    </div>
                                </div>
                            </div>
                          </>  
                          :
                          "Sin cliente"
                        }
                        <h3>
                            Equipo revisado
                            <br />
                        </h3>

                        <div className="form-group form-row">
                            <div className="col-6" >
                                 <label for="Padecimientos">Producto*</label>
                                <div className="input-group pb-1">
                                    <select className="custom-select" id="producto" >
                                        {
                                            productos.length >0 ?
                                            productos.map((object, index) => (
                                                <option value={object.value}  key={index}>{object.label}</option>
                                            ))
                                            :
                                            ""
                                                                    
                                        }
                                    </select>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" onClick={agregarProducto}>+</button>
                                    </div>
                                </div>
                        
                            </div>
                            <div className="col" >
                                <label>Productos seleccionados</label>
                                <ul className="list-group">
                                    {
                                        productosservicios.map((object,index)=>(
                                            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                {`${object.valor}`}
                                                <span className="badge badge-primary badge-pill" onClick={removerProducto(index)} >x</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div> 
                        {
                            producto.length>0 ?
                            producto.map((object,index)=>{
                                // var texto = objeto.fecha ? "-"+objeto.fecha :"";
                                // texto =`${objeto.valor} (${objeto.servicios.length})  ${texto}`; 
                                return(
                                    <div className="form-group form-row" key={index}>
                                        <div className="col-3" >
                                            <label htmlFor="Marca">Marca</label>
                                            <div className="input-group">
                                            <label htmlFor="Marca" className="form-control">{object.marca}</label>
                                            </div>
                                        </div>
                                        <div className="col-3" >
                                            <label htmlFor="Modelo">Modelo*</label>
                                            <div className="input-group">
                                                <label htmlFor="Modelo" className="form-control">{object.modelo}</label>
                                            </div>
                                        </div>
                                        <div className="col-3" >
                                            <label htmlFor="Serie">Serie*</label>
                                            <div className="input-group">
                                                <label htmlFor="Serie" className="form-control">{object.serie}</label>
                                            </div>
                                        </div>
                                        <div className="col-3" >
                                            <label htmlFor="Capacidad">Capacidad*</label>
                                            <div className="input-group">
                                                <label htmlFor="Capacidad" className="form-control">{object.capacidad}</label>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })
                                :
                                "sin resultados"
                        }
                        <h3>
                            Servicios
                            <br />
                        </h3>
                                <div className="row pl-3 form-group" >
                                    {
                                        servicios.length >0 ?
                                        servicios.map((object, index) => (
                                            <div className="form-check col-3" key={index}>
                                                <input className="form-check-input check-servicios" type="checkbox" name="servicios[]" value={object.id} id={`serviciciocheck${object.id}`}  />
                                                <label className="form-check-label" for={`serviciciocheck${object.id}`} >
                                                    {object.Nombre}
                                                </label>
                                            </div>
                                        ))
                                        :
                                        ""
                                    }
                                    <div className="col-6" style={{margin: 'auto', textAlign: 'center', }}>
                                <label htmlFor="Cantidad" style={{marginTop: '20px'}}>Descripción de servicios</label>
                                <div className="input-group">
                                    <textarea type="text" style={{resize: 'none', height:'100px'}} className="form-control"  name="descripcion_servicio" id="descripcion_servicio"></textarea>
                                </div>
                            </div>
                                </div>

                        
                        <h3>
                            Refacciones
                            <br />
                        </h3>
                        <div className="form-group form-row">
                            <div className="col-3" >
                                <label htmlFor="Cantidad">Cantidad</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="cantidad" id="cantidad"/>
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="Descripcion">Descripción</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="descripcion" id="descripcion"/>
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="Precio">Precio Unitario</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="precioUnitario" id="precioUnitario"/>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="button" onClick={agregarRefaccion} >Agregar</button>
                        </div>
                        <div className="form-group form-row">
                            <div className="col-3" >
                                <label htmlFor="Marca">Cantidad</label>
                                <div className="input-group">
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="Marca">Descripción</label>
                                <div className="input-group">
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="precioUnitario">Precio unitario</label>
                                <div className="input-group">
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="total">Total</label>
                                <div className="input-group">
                                </div>
                            </div>
                        </div>
                        {

                                refacciones.map((objeto,index)=> {
                                return ( 
                                    <div className="form-group form-row">
                                        <div className="col-3" >
                                            <div className="input-group">
                                            <label htmlFor="cantidad" className="form-control">{objeto.cantidad}</label>
                                            </div>
                                        </div>
                                        <div className="col-3" >
                                            <div className="input-group">
                                                <label htmlFor="descripcion" className="form-control">{objeto.descripcion}</label>
                                            </div>
                                        </div>
                                        <div className="col-3" >
                                            <div className="input-group">
                                                <label htmlFor="precioUnitario" className="form-control">{objeto.precioUnitario}</label>
                                            </div>
                                        </div>
                                        <div className="col-3" >
                                            <div className="input-group">
                                                <label htmlFor="total" className="form-control">{objeto.total}</label>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })

                        }
                        <div className="form-group form-row">
                            <div className="col-3" >
                                <label htmlFor="Cantidad">Mano de obra</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="mano_obra" id="cantidad"/>
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="Descripcion">Tiempo extra</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="tiempo_extra" id="descripcion"/>
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="Precio">Gastos de viaje</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="gastos_viaje" id="precioUnitario"/>
                                </div>
                            </div>
                            <div className="col-3" >
                                <label htmlFor="Precio">Otros gastos</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="otros gastos" id="precioUnitario"/>
                                </div>
                            </div>
                        </div>

                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/Ordenes" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <ModalTablaCliente titulo="Clientes" urlBusqueda="auth/catalogos/clientes-sinpaginacion" elegirElemento={cambiar} idModal="modalCliente" />
    <ModalTablaPolizas titulo="Pólizas" urlBusqueda="auth/servicio/polizas-sinpaginacion" elegirElemento={cambiar2} idModal="modalPoliza" />
    </>

    )
}

export default AgregarOrden;