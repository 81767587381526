import {useState, useEffect} from 'react'
import funciones from './funciones'
import API from './api'


export default (idModulo)  =>{
    const [permiso, setPermiso] = useState({"Crear":0,"Actualizar":0, "Visualizar":0,"Eliminar":0})

    const rutaPermiso = "auth/permisoModulo";
    useEffect(()=>{
        const traerData = async () =>{
            try {
                const response =   await API.post(rutaPermiso,{"idModulo":idModulo})
                if(response.status===200){
                    const data = await response.data
                    if(data[0]){
                        data[0].Crear=Number( data[0].Crear)
                        data[0].Actualizar=Number( data[0].Actualizar)
                        data[0].Visualizar=Number( data[0].Visualizar)
                        data[0].Eliminar=Number( data[0].Eliminar)
                        setPermiso(data[0])
                    }else{
                        setPermiso({"Crear":0,"Actualizar":0, "Visualizar":0,"Eliminar":0})  
                    }

                }else{
                    setPermiso({"Crear":0,"Actualizar":0, "Visualizar":0,"Eliminar":0})  

                }
            } catch (error) {
                setPermiso({"Crear":0,"Actualizar":0, "Visualizar":0,"Eliminar":0})  
            }


       
        }
        traerData()
    },[]);
    return permiso;
}