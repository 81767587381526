import React, {useState, useCallback} from 'react'
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

export default({buscarData, icono,ruta, permiso}) =>{
    const [buscar,setBuscar] = useState('');
    const mes1 =new Date().getMonth()+1;
    const mes2 =mes1+1;
    const datos = [{value:1, label:'Enero'}
                    ,{value:2, label:'Febrero'}
                    ,{value:3, label:'Marzo'}
                    ,{value:4, label:'Abril'}
                    ,{value:5, label:'Mayo'}
                    ,{value:6, label:'Junio'}
                    ,{value:7, label:'Julio'}
                    ,{value:8, label:'Agosto'}
                    ,{value:9, label:'Septiembre'}
                    ,{value:10, label:'Octubre'}
                    ,{value:11, label:'Noviembre'}
                    ,{value:12, label:'Diciembre'}
                ];
   // useCallback//https://es.reactjs.org/docs/hooks-reference.html#callback

    permiso = permiso.Crear === 1 ? true : false;
    const buscarDataPalabra = useCallback(
        () => {
            let mes1_temp = document.getElementById('mes1').value;
            let mes2_temp = document.getElementById('mes2').value;
            console.log(mes1_temp+", "+ mes2_temp);
            buscarData(buscar, mes1_temp, mes2_temp);
        },
        [buscar,buscarData],
      );

    return (
        <div className="input-group col-md-10 abosule-right">
            De:
            <div style={{padding: "0rem .5rem"}}>
                <select className="custom-select" id="mes1">
                    {
                        datos.map((object, index) => (
                            object.value==mes1 ?
                            <option value={object.value} selected key={index}>{object.label}</option>
                            :
                            <option value={object.value} key={index}>{object.label}</option>
                        ))
                    }
                </select>
            </div>
            A:
            <div style={{padding: "0rem .5rem"}}>
                <select className="custom-select" id="mes2">
                    {
                        datos.map((object, index) => (
                            object.value==mes2 ?
                            <option value={object.value} selected key={index}>{object.label}</option>
                            :
                            <option value={object.value} key={index}>{object.label}</option>
                        ))
                    }
                </select>
            </div>
            <div className="input-group-append ml-2" style={{padding: "0rem .5rem"}}>
                <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={ buscarDataPalabra } >Filtrar</button>
            </div>
            <input type="text" className="form-control" style={{padding: "0.45rem 1.07rem"}} onChange={ e => setBuscar (e.target.value)} placeholder="Buscar..." aria-label="Buscar..." aria-describedby="button-addon2"/>
            <div className="input-group-append ml-4">
                <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={ buscarDataPalabra } >Buscar</button>
            </div>
            {
                permiso ?
                <Link to={ruta}>
                <img  src={icono} style={{maxWidth:"55px"}} alt="settings" className="pl-3" ></img>
                </Link>
                :
                ""

            }
        </div>
    )
}
   
