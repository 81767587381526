import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import ModalTablaEquipos from '../../../componentes/ModalTablaCliente'
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import obtenerCatalogoSinDefecto from './../../../librerias/obtenerCatalogoSinDefecto'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
const Agregarpoliza =() => {
    const { register, handleSubmit, errors  } = useForm()
    const [cliente,setCliente] = useState({id:null,nombre:null});
    const [productos,setProductos] = useState({})
    const [servicios,setServicios] = useState({})
    const [productosservicios,setProductosservicios] = useState([])
    const [fechaServicio, setFechaServicio] = useState(new Date());


    const traerProductos =async (idCliente) => {
        var productos= await obtenerCatalogo('auth/catalogos/equipos-catalogo-cliente/'+idCliente)
        if(productos){
            setProductos(productos);
        }else{
            setProductos({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerServicios =async () => {
        var servicios= await obtenerCatalogoSinDefecto('auth/catalogos/servicios-catalogo')
        if(servicios){
            setServicios(servicios);
        }else{
            setServicios({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const cambiar =(cliente)=>{
        setCliente({ id: cliente.id,nombre:cliente.Empresa  });
        traerProductos( cliente.id);
        setProductosservicios([])
        
    }

    const getMes =(num)=>{
        let mes = '';
        if(num>12){
            num-=12;
        }
        switch(num){
            case 1:
               mes = 'ene';
                break;
            case 2:
                mes = 'feb';
                break;
            case 3:
                mes = 'mar';
                break;
            case 4:
                mes = 'abr';
                break;
            case 5:
                mes = 'may';
                break;
            case 6:
                mes = 'jun';
                break;
            case 7:
                mes = 'jul';
                break;
            case 8:
                mes = 'ago';
                break;
            case 9:
                mes = 'sep';
                break;
            case 10:
                mes = 'oct';
                break;
            case 11:
                mes = 'nov';
                break;
            case 12:
                mes = 'dic';
                break;
        }
        return mes;
    } 
    
    const evitarPasarse =(num)=>{
        if(num>12){
            num-=12;
        }
        return num;
    }

    const generarFolio =()=>{
        let fecha = new Date();
        let folio = fecha.getFullYear()+""+fecha.getMonth()+""+fecha.getDay()+""+fecha.getHours()+""+fecha.getMinutes()+""+
            fecha.getSeconds()+""+fecha.getMilliseconds();
        if(folio.length < 14){
            folio+="0";
        }else if(folio.length>14){
            folio = folio.substring(0, 14);
        }
        return folio;
    }
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            let formData = new FormData(document.getElementById("form-poliza"));
            let periodo = document.getElementById('Periodo').value;
            productosservicios.forEach((element)=>(formData.append("productos[]", JSON.stringify(element))))
            formData.append("cliente_id", cliente.id)
            let fecha = new Date(document.getElementById('fechaServicio').value);
            let mes = fecha.getMonth()+1;
            let vueltas = 12/periodo;
            for(let i=1; i<=vueltas; i++){
                    formData.append('mes_id[]', evitarPasarse(i*periodo+mes));
            }
            formData.append('folio', generarFolio());
            const response =  await API.post('auth/servicio/polizas-agregar', formData);
            if(response.status===201){
               swal({
                    title: "Registrado",
                    text: "La poliza se agrego correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/polizas')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            document.getElementById("enviar").disabled = false;
          }
  
       }
    const paginaCliente = ()=>{
    window.open("/?ruta=cliente")
    }
    useEffect(
    ()=>{
        traerServicios();
    }
    ,[]);
    const agregarProducto = () => {
        var selectProducto = document.getElementById("producto")
        var idProducto =selectProducto.value
        if(idProducto=="")
        return false;
        var valorProducto = selectProducto.options[selectProducto.selectedIndex].text;
        // revisamos si ya existe
        var found = productosservicios.find(pro =>{
            return pro.producto_id===idProducto 
        } );
        // se encontro
        if(found){
            alert("Producto repetido")
            return false;
        }
        var arregloServicios = [];
        [...document.getElementsByClassName("check-servicios")].forEach(
            (element, index, array) => {
               if(element.checked){
                arregloServicios.push(element.value);
               }
            }
        );
        if(arregloServicios.length==0){
            alert("Favor de seleccionar un servicio")
            return false;    
        }
        const clsProducto = {
            producto_id: idProducto,
            valor: valorProducto,
            servicios: arregloServicios,
        };
        setProductosservicios(productose => [...productose, clsProducto]);
    };
    const removerProducto = indexx => () => {
        setProductosservicios(productose => [...productose.filter((producto, index) =>     (   indexx !==index     ))]);
    };
    return (
    <>
    <Titulo titulo="Pólizas" tituloBajo="Pólizas&gt; Agregar Póliza"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Pólizas</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)} id="form-poliza">
                        <h3>
                            Asignar póliza al cliente
                            <br />
                            <small>Selecciona los productos al igual que los servicios que tendrá dicho producto</small>
                        </h3>
                        <div className="form-group form-row">
                            <div className="col-6" >
                                <label htmlFor="catsucursal_id">Cliente*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" readOnly={true} value={cliente.nombre} name="cliente" id="cliente"/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#modalCliente" type="button">Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col" >
                                <label htmlFor="">&nbsp;</label>
                                <div >
                                    |&nbsp;<button target="_blank"className="btn btn-decondary " onClick={paginaCliente}  type="button">Agregar cliente</button>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <h3>
                        Seleccione los productos del cliente que cubrirá con esta póliza
                            <br />
                            <small>Selecciona el cliente o dalo de alta en caso de que aún no exista en la base de datos</small>
                        </h3>
                        <div className="form-group form-row">
                            <div className="col-6" >
                                 <label for="Padecimientos">Producto*</label>
                                <div className="input-group pb-1">
                                    <select className="custom-select" id="producto" >
                                        {
                                            productos.length >0 ?
                                            productos.map((object, index) => (
                                                <option value={object.value}  key={index}>{object.label}</option>
                                            ))
                                            :
                                            ""
                                                                    
                                        }
                                    </select>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" onClick={agregarProducto}>+</button>
                                    </div>
                                </div>
                                <div className="row" >
                                    {
                                        servicios.length >0 ?
                                        servicios.map((object, index) => (
                                            <div className="form-check col-6" key={index}>
                                                <input className="form-check-input check-servicios" type="checkbox" value={object.id} id={`serviciciocheck${object.id}`}  />
                                                <label className="form-check-label" for={`serviciciocheck${object.id}`} >
                                                    {object.Nombre}
                                                </label>
                                            </div>
                                        ))
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                            <div className="col" >
                                <label>Productos seleccionados</label>
                                <ul className="list-group">
                                    {
                                        productosservicios.map((object,index)=>(
                                            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                {`${object.valor} (${object.servicios.length})`}
                                                <span className="badge badge-primary badge-pill" onClick={removerProducto(index)} >x</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>

                        </div>    
                        <h3>
                            Periodo e inicio de servicio
                            <br />
                            <small>Define el periodo que deberá pasar entre cada visita de servicio y desde que fecha se realizara el primer servicio de esta póliza</small>
                        </h3>  
                        <div className="form-group form-row">
                            <div className="col" >
                                <label htmlFor="Periodo">Periodo*</label>
                                <select class="form-select" className="form-control" id="Periodo" name="Periodo" ref={register({ required: true })}>
                                    <option value="1">1 mes</option>
                                    <option value="2">2 meses</option>
                                    <option value="3">3 meses</option>
                                    <option value="4">4 meses</option>
                                    <option value="6">6 meses</option>
                                    <option value="12">12 meses</option>
                                </select>
                                {errors.Periodo &&
                                <div className="error-form text-red p-small">
                                   Este campo no puede estar vacío
                                </div>
                                }
                            </div>    
                            <div className="col" >
                            <label htmlFor="FechaPrimerServicio">Fecha*</label>
                                <DatePicker
                                            selected={fechaServicio}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            name="fechaServicio"
                                            id="fechaServicio"
                                            onChange={date=>setFechaServicio(date)}
                                                />       
                            </div>
                        </div>  
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Nota">Nota</label>
                                <input type="text" className="form-control" id="Nota" name="Nota"  ref={register}  />
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/polizas" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <ModalTablaEquipos titulo="Clientes" urlBusqueda="auth/catalogos/clientes-sinpaginacion" elegirElemento={cambiar} idModal="modalCliente" />
    </>

    )
}

export default Agregarpoliza;