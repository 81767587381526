import React, {useEffect} from 'react';
import Titulo from '../../componentes/Titulo'
import API from '../../librerias/api';
import history from '../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";

const EditarRoles =() => {
    const URLROLLEER = 'auth/admin/roles-edit'
    let { id } = useParams();
    const rol  =obtenerData(URLROLLEER,id)
    const { register, handleSubmit, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(rol) =>{
            console.log(rol)
            setValue('Nombre', rol.Nombre)
            setValue('Descripcion', rol.Descripcion)
            }
        asignar(rol)
        }
    ,[setValue,rol]);
    
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            const response =  await API.put('auth/admin/roles/'+rol.id, data);
            if(response.status===200){
               swal({
                    title: "Actualizado",
                    text: "El rol se ha actualizado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/roles')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            document.getElementById("enviar").disabled = false;
          }
  
       }
    return (
    <>
    <Titulo titulo="Roles" tituloBajo="Roles &gt; Editar rol"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-2 linea">Rol</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label for="Nombre">Rol*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label for="Descripcion">Descripción*</label>
                            <textarea class="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div class="text-right">
                            <Link to="/roles" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default EditarRoles;