import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
// 
import object from './../../equipos.png';
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'

const AgregarEquipo =() => {
    const { register, handleSubmit, errors  } = useForm()
    const [marcas,setMarcas] = useState({})
    const traerMarcas =async () => {
        var marcas= await obtenerCatalogo('auth/catalogos/marcas-catalogo')
        if(marcas){
            setMarcas(marcas);
        }else{
            setMarcas({value: "", label: '--Selecciona un valor--'})
        }
    }

    const onSubmit =async (data,e) => { 
        try
        {
            document.getElementById("enviar").disabled = true;
            let formData = new FormData(document.getElementById("formulario"));
            const response =  await API.post('auth/catalogos/equipos-agregar', formData);
            if(response.status===201){
               swal({
                    title: "Registrado",
                    text: "Equipo agregado correctamente",
                    icon: "success",
                    button: "Ok"
               });
           
                history.push('/equipos')

            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
        } catch (error) {
        funciones.tratarerrores(error,document.getElementById("respuesta"));
        if(document.getElementById("enviar"))
            document.getElementById("enviar").disabled = false;
    }
    }
    useEffect(
        ()=>{
            traerMarcas();
        }
    ,[]);
    const onChangeImg =  e => {
        // setValue("img", e.target.files[0]);
          // Creamos el objeto de la clase FileReader
        let reader = new FileReader();
        // Leemos el archivo subido y se lo pasamos a nuestro fileReader
        reader.readAsDataURL(e.target.files[0]);
        // Le decimos que cuando este listo ejecute el código interno
        reader.onload = ()=>{
            let img = document.getElementById('img-carga');
            img.src = reader.result;
        };
    }
    return (
    <>
    <Titulo titulo="Equipo" tituloBajo="Equipo &gt; Agregar Equipo"></Titulo>

    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3>Agregar equipo</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)} id="formulario">
                        <div className="form-group">
                            <div className="col-4 mx-auto text-center" >
                                <img src={object} id="img-carga" alt="Avatar" style={{"borderRadius":"50%","height":"136px"}} /> 
                                <input type="file" className="form-control" onChange={onChangeImg} id="imgD" name="imgD" ref={register()} accept="image/png,image/jpeg"  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Nombre">Nombre*</label>
                                <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                                {errors.Nombre &&
                                <div className="error-form text-red p-small">
                                   Este campo no puede estar vacío
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Modelo">Modelo*</label>
                                <input type="text" className="form-control" id="Modelo" name="Modelo"  ref={register({ required: true })}  />
                                {errors.Modelo &&
                                <div className="error-form text-red p-small">
                                   Este campo no puede estar vacío
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col">
                                <label for="marcas">Marca*</label>
                                <select className="form-control" name="marca_id" id="marca_id" ref={register({ required: true })} >
                                {
                                    marcas.length >0 ?
                                    marcas.map((object, index) => (
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                }
                                </select>
                                {errors.marca_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Codigo">Código*</label>
                                <input type="text" className="form-control" id="Codigo" name="Codigo"  ref={register({ required: true })}  />
                                {errors.Codigo &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Serie">Número serie*</label>
                                <input type="text" className="form-control" id="Serie" name="Serie"  ref={register({ required: true })}  />
                                {errors.Serie &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Capacidad">Capacidad*</label>
                                <input type="text" className="form-control" id="Capacidad" name="Capacidad"  ref={register({ required: true })}  />
                                {errors.Capacidad &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Descripcion">Descripción</label>
                                <input type="text" className="form-control" id="Descripcion" name="Descripcion"  ref={register({ required: true })}  />
                                {errors.Descripcion &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/equipos" className="btn btn-secondary">Regresar</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarEquipo;