import React, { useEffect,useState } from 'react';
import {    Link  } from "react-router-dom";
import API from '../../librerias/api'
import funciones from '../../librerias/funciones'
import logo from './../../logo-3.png';  
import cara from './../../cara.png'; 
import logoBig from './../../logo-big.png'; 
import history from '../../librerias/history';
import'./header.css'; 

// iconos header
// import engrane from './iconos-header/engrane.png'; 
// import interrogacion from './iconos-header/interrogacion.png'; 
// import notificacion from './iconos-header/notificacion.png'; 
// import lupa from './iconos-header/lupa.png'; 
import triangulo from './iconos-header/triangulo.png'; 
import calculadora from './iconos-header/calculadora.png'; 

const Header =() => {
    const [resultado, setResultado] = useState("");
    const [error, setError] = useState(false);
    const [valor, setValor] = useState("");

    const salir =async () => { 
        try {
        const response =  await API.post('auth/logout');
            if(response.status===200){
                localStorage.clear();
                history.push('/')
                window.location.replace('/');
            }
        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta-salir-login"));
        
        }
  
    }
    useEffect(() => { 
        try
        {
            const traerData = async () =>{
                try {
                    const response =  await API.post('auth/user');
                    if(response.status===200){
                        // console.log(response)
                        var usarioHeader=response.data;
                        console.log(usarioHeader)
                        document.getElementById("nombreUsuarioHeader").innerHTML = usarioHeader.name;
                        document.getElementById("puestoHeader").innerHTML = usarioHeader.cat_rol.Nombre;
                        document.getElementById("img-perfil-1").src=usarioHeader.img;
                        document.getElementById("img-perfil-2").src=usarioHeader.img;
                    }
                  } catch (error) {
                      console.log(error)
                    funciones.tratarerrores(error,document.getElementById("nombreUsuarioHeader"));
                  }
            }
            traerData();
        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("nombreUsuarioHeader"));
        }
        
    },[]);
    const errorImg = e =>{
        e.target.src=cara;
    }
    const calcularSinIva=  () =>{
        try {
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if(RE.test(valor)  ){
                var valorDec = valor
                valorDec=Number(valorDec)/1.16
                let t=valorDec.toString();
                let regex=/(\d*.\d{0,3})/;
                setResultado(t.match(regex)[0]);
                setError(false)
            }else{
                setError(true)
            }
        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"),error);
        }
    }
    const copiarResultado =() =>{
        try {
            
            document.getElementById("resultadoCalculadora").select()
            // Copia el texto seleccionado
            document.execCommand("copy");
            document.getElementById("close-modal-calculadora").click();
        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"),error);
        }
    } 
return (
    <header className="header">
        <nav className="navbar fixed-top pl-0" style={{backgroundColor: "white"}}>   
            <div className="search-box">
                <button className="dismiss"><i className="ion-close-round"></i></button>
                <form id="searchForm" action="#" role="search">
                    <input type="search" placeholder="Search something ..." className="form-control" />
                </form>
            </div>
            <div className="navbar-holder d-flex align-items-center align-middle justify-content-between" >
                <div className="navbar-header" >
                    <Link to="/#" className="navbar-brand">
                        <div className="brand-image brand-big">
                            <img  src={logoBig} alt="logo" className="logo-big" />
                        </div>
                        <div className="brand-image brand-small">
                            <img  src={logo} alt="logo" className="logo-small" />
                        </div>
                    </Link>
                    <Link id="toggle-btn" to="#" className="menu-btn active" style={{"width":"30px","height":"25px","marginLeft":"-43px","position":"relative"}}>
                        <div style={{"position":"absolute","right":"-15px"}}>
                        <span></span>
                        <span></span>
                        <span></span>
                        </div>
                    </Link>
                </div>
                <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center pull-right">
                    <li className="nav-item dropdown row ml-3" style={{width: "200px"}} >
                        <div className="mitad" style={{width: "30%"}}>
                            <Link rel="nofollow" data-target="#" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src={cara} id="img-perfil-1" alt="..." className="avatar rounded-circle"  onError={errorImg} />
                            </Link>
                        </div>
                        <div className="mitad pt-1 bb-0" style={{width: "60%"}}>
                            <Link rel="nofollow" data-target="#" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span style={{color: "black",fontSize: "13px"}}><b id="nombreUsuarioHeader" >Cargando..</b></span>
                            <div>
                                <span  style={{float: "left", color:"black"}}><b id="puestoHeader" >Gerente</b></span>
                                <span className="pl-1" id="flecha-abajo-hearder" style={{float: "left"}}>
                                    <img src={triangulo} alt="triangulo" style={{width:"12px"}}></img>

                                </span>
                            </div>
                            </Link>
                        </div>
                        {/* <a id="user" rel="nofollow" data-target="#" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link"><img src={logo} alt="..." className="avatar rounded-circle" />
                        </a> */}
                        <ul aria-labelledby="user" className="user-size dropdown-menu">
                            <li className="welcome">
                                {/* <a to="#" className="edit-profil"><i className="la la-gear"></i></a> */}
                                <img src={cara} alt="..." className="rounded-circle" id="img-perfil-2" onError={errorImg} />
                            </li>
                            {/* <li>
                                <a to="pages-profile.html" className="dropdown-item"> 
                                    Profile
                                </a>
                            </li>
                            <li>



                                <a to="app-mail.html" className="dropdown-item"> 
                                    Messages
                                </a>
                            </li>
                            <li>
                                <a to="#" className="dropdown-item no-padding-bottom"> 
                                    Settings
                                </a>
                            </li>
                            <li className="separator"></li>
                            <li>
                                <a to="pages-faq.html" className="dropdown-item no-padding-top"> 
                                    Faq
                                </a>
                            </li> */}
                            <div id="respuesta-salir-login" ></div>
                            <li><Link rel="nofollow"  onClick={ ()=>{salir()} }  to="/" className="dropdown-item logout text-center"><i className="ti-power-off"></i></Link></li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        {/* <a to="#off-canvas" className="open-sidebar"><i className="la la-ellipsis-h"></i></a> */}
                    </li>
                </ul>
            </div>
        </nav>
        {/* <!-- Modal --> */}
        <div className="modal fade" id="modalCalculadora" tabindex="-1" role="dialog" aria-labelledby="modalCalculadoraLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalCalculadoraLabel">Calcular</h5>
                    <button type="button" className="close" data-dismiss="modal" id="close-modal-calculadora" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="input-group mb-3">
                        <input type="text" id="montoPagarIvaCalc" value={valor} onChange={(e)=>{ setValor(e.target.value)}} className="form-control" placeholder="Colocala la cantidad con Iva" aria-label="Colocala la cantidad con Iva" aria-describedby="button-addon2"/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" onClick={()=>{calcularSinIva()}} >Calcular</button>
                        </div>
                        {
                            error ?
                            <>
                                <br/>
                                <div>Favor de colocar un número</div>
                            </>
                            :
                            ""
                        }
                        
                    </div>
                    {
                        resultado ?
                        <>
                        <p>Resultado:</p>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" id="resultadoCalculadora" value={resultado} placeholder="Colocala la cantidad con Iva" aria-label="Colocala la cantidad con Iva" aria-describedby="button-addon3"/>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" id="button-addon3" onClick={()=>{copiarResultado()}}  >Copiar</button>
                            </div>
                        </div>
                        </>
                        :
                        ""


                    }

                </div>
                {/* <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div> */}
                </div>
            </div>
        </div>
    </header>

)
}


export default Header;