import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm,Controller } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import object from './../../equipos.png';
const VerEquipo =() => {
    const URLEQUIPOLEER = 'auth/catalogos/equipos-mostrar/'
    let { id } = useParams();
    const equipo  =obtenerData(URLEQUIPOLEER,id,1)
    const { register, handleSubmit, errors,setValue,control  } = useForm()
    const [marcas,setMarcas] = useState({})


    const traerMarcas =async () => {
        var marcas= await obtenerCatalogo('auth/catalogos/marcas-catalogo')
        if(marcas){
            setMarcas(marcas);
        }else{
            setMarcas({value: "", label: '--Selecciona un valor--'})
        }
    }
    useEffect(
        ()=>{
        const asignar =(equipo) =>{
            console.log(equipo)
            setValue('Nombre', equipo.Nombre)
            setValue('Modelo', equipo.Modelo)
            setValue('Descripcion', equipo.Descripcion)
            setValue('Codigo', equipo.Codigo)
            setValue('Serie', equipo.Serie)
            document.getElementById("img-carga").src=equipo.img+"?v="+Math.random();
            
        
            }
        asignar(equipo)
        traerMarcas()
        
        }
    ,[equipo]);
    
    const errorImg = e =>{
        e.target.src=object;
    }
    return (
    <>
     <Titulo titulo="Equipo" tituloBajo="Editar &gt; equipo"></Titulo>

    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Equipo</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form id="formulariod">
                        <div className="form-group">
                            <div className="col-4 mx-auto text-center" >
                                <img src=""  onError={errorImg} id="img-carga" alt="Avatar" style={{"borderRadius":"50%","height":"136px"}} /> 
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Nombre">Nombre*</label>
                                <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                                {errors.Nombre &&
                                <div className="error-form text-red p-small">
                                   Este campo no puede estar vacío
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Modelo">Modelo*</label>
                                <input type="text" className="form-control" id="Modelo" name="Modelo"  ref={register({ required: true })}  />
                                {errors.Modelo &&
                                <div className="error-form text-red p-small">
                                   Este campo no puede estar vacío
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col">
                                <label for="marcas">Marca*</label>
                                <select className="form-control" name="marca_id" id="marca_id" ref={register({ required: true })} >
                                {
                                    marcas.length >0 ?
                                    marcas.map((object, index) => (
                                        equipo.id ?
                                        equipo.marca_id ===object.value ?
                                        <option value={object.value} selected  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        

                                    ))
                                    :
                                    ""
                                                            
                                }    
                                </select>
                                {errors.marca_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Codigo">Código*</label>
                                <input type="text" className="form-control" id="Codigo" name="Codigo"  ref={register({ required: true })}  />
                                {errors.Codigo &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Serie">Número serie*</label>
                                <input type="text" className="form-control" id="Serie" name="Serie"  ref={register({ required: true })}  />
                                {errors.Serie &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Capacidad">Capacidad*</label>
                                <input type="text" className="form-control" id="Capacidad" name="Capacidad"  ref={register({ required: true })}  />
                                {errors.Capacidad &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>   
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Descripcion">Descripción</label>
                                <input type="text" className="form-control" id="Descripcion" name="Descripcion"  ref={register({ required: true })}  />
                                {errors.Descripcion &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>  
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/equipos" className="btn btn-secondary">Regresar</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerEquipo;