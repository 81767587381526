
import React  , {useEffect,useState}  from "react";
import Login from "./paginas/Login";
import Cerrar from "./paginas/Cerrar";
import {
  Switch,
  Route
} from "react-router-dom";
import Header from './componentes/Header/Header';
import MenuLateral from './componentes/MenuLateral';
import Footer from './componentes/Footer/Footer';

import Roles from './paginas/Roles/Roles'
import AgregarRoles from './paginas/Roles/AgregarRoles'
import VerRol from './paginas/Roles/VerRol'
import EditarRoles from './paginas/Roles/EditarRoles'


import Permisos from './paginas/Permisos/Permisos'
import AgregarPermiso from './paginas/Permisos/AgregarPermisos'
import EditarPermisos from './paginas/Permisos/EditarPermisos'
import VerPermisos from './paginas/Permisos/VerPermisos'

import Usuarios from './paginas/Usuarios/Usuarios'
import AgregarUsuario from './paginas/Usuarios/AgregarUsuario'
import EditarUsuario from './paginas/Usuarios/EditarUsuario'
import VerUsuario from './paginas/Usuarios/VerUsuario'

import Marcas from './paginas/Catalogos/Marcas/Marcas'
import Agregarmarca from './paginas/Catalogos/Marcas/Agregarmarca'
import Editarmarca from './paginas/Catalogos/Marcas/Editarmarca'
import Vermarca from './paginas/Catalogos/Marcas/Vermarca'

import Bienvenido from './paginas/Bienvenido'

import Equipos from './paginas/Catalogos/Equipos/Equipos'
import AgregarEquipo from './paginas/Catalogos/Equipos/AgregarEquipo'
import EditarEquipo from './paginas/Catalogos/Equipos/EditarEquipo'
import VerEquipo from './paginas/Catalogos/Equipos/VerEquipo'


import Catclientes from './paginas/Catalogos/Clientes/Catclientes'
import AgregarCliente from './paginas/Catalogos/Clientes/AgregarCliente'
import EditarCliente from './paginas/Catalogos/Clientes/EditarCliente'
import VerCliente from './paginas/Catalogos/Clientes/VerCliente'

import Servicio from './paginas/Catalogos/Servicios/Servicio'
import Agregarservicio from './paginas/Catalogos/Servicios/Agregarservicio'
import Editarservicio from './paginas/Catalogos/Servicios/Editarservicio'
import Verservicio from './paginas/Catalogos/Servicios/Verservicio'

import Polizas from './paginas/Servicio/Poliza/Polizas'
import ProximasPolizas from './paginas/Servicio/ProximaPoliza/ProximasPolizas'
import Agregarpoliza from './paginas/Servicio/Poliza/Agregarpoliza'
import Editarpoliza from './paginas/Servicio/Poliza/Editarpoliza'
import Verpoliza from './paginas/Servicio/Poliza/Verpoliza'

import Ordenes from './paginas/Reportes/Orden/Ordenes'
import AgregarOrden from './paginas/Reportes/Orden/AgregarOrden'
//import Editarpoliza from './paginas/Servicio/Poliza/Editarpoliza'
//import Verpoliza from './paginas/Servicio/Poliza/Verpoliza'

import Dashboard from './paginas/Dashboard'

import Editarserviciop from './paginas/Servicio/Servicio/EditarServicio'

// import AgregarPermiso from './paginas/Permisos/AgregarPermiso'

const App =()=>{
  // localStorage.clear();
  const [usuario,setUsuario] = useState(false);
  useEffect(() => { 
    if(localStorage.getItem('user')){
      const user = JSON.parse(localStorage.getItem('user'));
      var ims = Date.parse(user.expires_at);
      var fecha = new Date(ims);
       var fechaActualIms = Date.now();
       var fechaActual = new Date(fechaActualIms);
       if(fechaActual<=fecha){
        setUsuario(true);
       }else{
        setUsuario(false);
        localStorage.clear();
       }
    }
    
    },[]);

  return (

      usuario===false && localStorage.getItem('user')==null ?
      <Login cambiarUsuario={ ()=>{setUsuario(true);;  }} />
      :
      <>
      <div className="page" >
      <Header /> 
      <div className="page-content d-flex align-items-stretch" >
        <MenuLateral />
        <div className="content-inner">
        <div className="container-fluid">
          <Switch>
              {/* <Route path="/roles">
                <Roles></Roles>
              </Route> */}

              <Route path="/roles">
                <Roles></Roles>
              </Route>
              <Route path="/AgregarRoles">
                <AgregarRoles></AgregarRoles>
              </Route>
              <Route exact path='/editar-rol/:id?' component={EditarRoles}/>  
              <Route exact path='/ver-rol/:id?' component={VerRol}/>  
              <Route path="/usuarios">
                <Usuarios></Usuarios>
              </Route>
              <Route path="/AgregarUsuario">
                <AgregarUsuario></AgregarUsuario>
              </Route>
              <Route exact path='/editar-usuario/:id?' component={EditarUsuario}/>  
              <Route exact path='/ver-usuario/:id?' component={VerUsuario}/>
              <Route path="/permisos">
                <Permisos></Permisos>
              </Route>
              <Route path="/AgregarPermisos">
                <AgregarPermiso></AgregarPermiso>
              </Route>
              <Route exact path='/editar-permiso/:id?' component={EditarPermisos}/>  
              <Route exact path='/ver-permiso/:id?' component={VerPermisos}/>
              <Route exact path='/' component={Bienvenido}/>
              <Route path="/marcas">
                <Marcas></Marcas>
              </Route>
              <Route path="/Agregarmarca">
                <Agregarmarca></Agregarmarca>
              </Route>
              <Route exact path='/editar-marca/:id?' component={Editarmarca}/>  
              <Route exact path='/ver-marca/:id?' component={Vermarca}/>  
              <Route path="/equipos">
                <Equipos></Equipos>
              </Route>
              <Route path="/Agregarequipo">
                <AgregarEquipo></AgregarEquipo>
              </Route>
              <Route exact path='/editar-equipo/:id?' component={EditarEquipo}/>  
              <Route exact path='/ver-equipo/:id?' component={VerEquipo}/>  
              <Route path="/clientes">
                <Catclientes></Catclientes>
              </Route>
              <Route path="/AgregarCliente">
                <AgregarCliente></AgregarCliente>
              </Route>
              <Route exact path='/editar-cliente/:id?' component={EditarCliente}/>
              <Route exact path='/ver-cliente/:id?' component={VerCliente}/>
              <Route path="/servicios">
                <Servicio></Servicio>
              </Route>
              <Route path="/Agregarservicio">
                <Agregarservicio></Agregarservicio>
              </Route>
              <Route exact path='/editar-servicio/:id?' component={Editarservicio}/>
              <Route exact path='/ver-servicio/:id?' component={Verservicio}/>
              <Route path="/polizas">
                <Polizas></Polizas>
              </Route>
              <Route path="/Agregarpoliza">
                <Agregarpoliza></Agregarpoliza>
              </Route>
              <Route path="/ProximasPolizas">
                <ProximasPolizas></ProximasPolizas>
              </Route>
              <Route path="/ordenes">
                <Ordenes></Ordenes>
              </Route>
              <Route path="/AgregarOrden">
                <AgregarOrden></AgregarOrden>
              </Route>
              <Route exact path='/editar-poliza/:id?' component={Editarpoliza}/>
              <Route exact path='/ver-poliza/:id?' component={Verpoliza}/>
              <Route exact path='/editar-servicio/:id/:idservicio?' component={Editarserviciop}/>
              <Route path="/dashboard">
                <Dashboard></Dashboard>
              </Route>
              <Route path="/">
                <Bienvenido></Bienvenido>
              </Route>
          </Switch>
        </div>
          <Footer />
        </div>
      </div>
      </div>
      </>
  );
}
export default App;
