import React, {useState, useEffect} from 'react';
import Titulo from '../../componentes/Titulo'
import API from '../../librerias/api';
import history from '../../librerias/history';
import { useForm,Controller  } from 'react-hook-form'
import funciones from '../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import Select from 'react-select';
import obtenerCatalogo from './../../librerias/obtenerCatalogo'



import perfil from './perfil.png';


  
const AgregarUsuario =() => {
    const [roles,setRoles] = useState({})
    const [cargando,setCargado] = useState(true)
    const { control,register, handleSubmit,setValue, errors  } = useForm()
    

    const traerRoles =async () => {
        const roles= await obtenerCatalogo('auth/admin/roles-catalogo')
        setRoles(roles);
        setCargado(false)
        setValue("catrol_id",{value: null, label: '--Selecciona un valor--'})
    }


    useEffect(
        ()=>{
            traerRoles();
        },[setValue]
    )

    const onSubmit =async (data,e) => { 
        try {
            // document.getElementById("enviar").disabled = true;
            if(data.catrol_id.value){
                data.catrol_id = data.catrol_id.value;
                let formData = new FormData(document.getElementById("formulario"));
                formData.append("catrol_id",data.catrol_id)
                const response =  await API.post('auth/admin/usuarios-agregar', formData);
                if(response.status===201){
                   swal({
                        title: "Registrado",
                        text: "El usuario se ha registrado correctamente",
                        icon: "success",
                        button: "Aceptar"
                   }); 
                    document.getElementById("enviar").disabled = false;
                    history.push('/usuarios')
                }else{
                    document.getElementById("enviar").disabled = false;
                    funciones.tratarerrores(response,document.getElementById("respuesta"));
                }
            }else{
                document.getElementById("respuesta").innerHTML ="Favor de seleccionar un rol";
            }


          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            console.log(error)
          }
  
       }
    const handleChangeRol =  e => {
        setValue("catrol_id", e);
    }
    const onChangeImg =  e => {
        // setValue("img", e.target.files[0]);
          // Creamos el objeto de la clase FileReader
        let reader = new FileReader();
        // Leemos el archivo subido y se lo pasamos a nuestro fileReader
        reader.readAsDataURL(e.target.files[0]);
        // Le decimos que cuando este listo ejecute el código interno
        reader.onload = ()=>{
            let img = document.getElementById('img-carga');
            img.src = reader.result;
        };
    }
    return (
    <>
    <Titulo titulo="Usuarios" tituloBajo="Crear &gt; usuario"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Usuarios</h4>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data" id="formulario">
                        <div className="form-group">
                            {/* <label for="name">Nombre*</label> */}
                            <div className="col-4 mx-auto text-center" >
                                <img src={perfil} id="img-carga" alt="Avatar" style={{"borderRadius":"50%","height": "136px"}} /> 
                                <input type="file" className="form-control" onChange={onChangeImg} id="imgD" name="imgD" ref={register()} accept="image/png,image/jpeg"  />

                            </div>
                            {/* <input type="text" className="form-control" id="name" name="name" ref={register({ required: true })}  /> */}
                        </div>
                        <div className="form-group">
                            <label for="name">Nombre*</label>
                            <input type="text" className="form-control" id="name" name="name" ref={register({ required: true })}  />
                            {errors.name &&
                            <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label for="Email">Correo*</label>
                            <input type="email" className="form-control" id="email" name="email" ref={register( { required: true} )}  />
                            {errors.email &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío y debe ser un correo!
                            </div>
                            }
                        </div>
                        <div className="form-group form-row">
                            <div className="col-6" >
                                <label for="password">Contraseña*</label>
                                <input type="password" className="form-control" id="password" name="password" ref={register( { required: true, minLength: 6} )}  />
                                {errors.password &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío y debe ser mayor  a 6 caracteres!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label for="password_confirmation">Confiramción de contraseña*</label>
                                <input type="password" className="form-control" id="password_confirmation" name="password_confirmation" ref={register( { required: true, minLength: 6} )}  />
                                {errors.password_confirmation &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío y debe ser mayor  a 6 caracteres!
                                </div>
                                }
                            </div>

                        </div>
                        <div className="form-group">
                            <label for="rol">Rol*</label>
                            {cargando ?
                            "Cargando"
                            :
                            <Controller
                                name="catrol_id"
                                control={control}
                                render={({ onChange, onBlur }) => (
                                    <Select
                                    placeholder='--Selecciona un valor--'
                                      onChange={handleChangeRol}
                                        options={roles}
                                        isDisabled={false}

                                    />
                                )}
                            />
                            }
                        </div>
                        <div id="respuesta" ></div>
                        
                        <div class="text-right">
                            <Link to="/usuarios" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default AgregarUsuario;