import React, {useState,useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import ModalTablaEquipos from '../../../componentes/ModalTablaCliente'
import obtenerCatalogo from './../../../librerias/obtenerCatalogo'
import obtenerCatalogoSinDefecto from './../../../librerias/obtenerCatalogoSinDefecto'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


const VerPoliza =() => {
    const URLSERVICIOLEER = 'auth/servicio/polizas-mostrar/'
    let { id } = useParams();
    const poliza  =obtenerData(URLSERVICIOLEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()
    const URLPOLIZAEQUIPOELIMINAR = 'auth/servicio/polizas-eliminar-equipo/'
    const [cliente,setCliente] = useState({id:null,nombre:null});
    const [productos,setProductos] = useState({})
    const [servicios,setServicios] = useState({})
    const [productosservicios,setProductosservicios] = useState([])
    const [fechaServicio, setFechaServicio] = useState(new Date());
    const [equiposeleccionado,setEquipoSeleccionado] = useState({})



    const traerProductos =async (idCliente) => {
        var productos= await obtenerCatalogo('auth/catalogos/equipos-catalogo-cliente/'+idCliente)
        if(productos){
            setProductos(productos);
        }else{
            setProductos({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerServicios =async () => {
        var servicios= await obtenerCatalogoSinDefecto('auth/catalogos/servicios-catalogo')
        if(servicios){
            setServicios(servicios);
        }else{
            setServicios({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    useEffect(
        ()=>{
        const asignar =(poliza) =>{
            if(poliza.id){
                // setValue('Nombre', poliza.Nombre)
                // setValue('Descripcion', poliza.Descripcion)
                traerServicios();
                var equipoArray = [];
                var equipoIndice = 0;
                if(poliza.equipo)
                poliza.equipo.forEach(element=>{
                    equipoArray[equipoIndice++] ={
                        id:element.id,
                        producto_id: element.equipo_id,
                        valor: element.equipo.Nombre,
                        servicios: element.servicio.map(({servicio_id})=> (servicio_id)),
                        fecha:element.fechaServicio ,
                    }
                })
                setProductosservicios(equipoArray);
                setCliente({id:poliza.cliente.id,nombre:poliza.cliente.Empresa})
                traerProductos(poliza.cliente.id)
                setValue('Periodo', poliza.Periodo)
                setValue('Nota', poliza.Nota)
                setFechaServicio(new Date(poliza.fechaServicio.replace(/-/g, '\/')))
            }
        }
        asignar(poliza)
        }
    ,[setValue,poliza]);
    
    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            let formData = new FormData(document.getElementById("form-poliza"));
            productosservicios.forEach((element)=>(formData.append("productos[]", JSON.stringify(element))))
            const response =  await API.post('auth/servicio/polizas-actualizar/'+poliza.id, formData);
            if(response.status===200){
               swal({
                    title: "Actualizado",
                    text: "La poliza se ha actualizado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/polizas')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
              console.log(error)
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            document.getElementById("enviar").disabled = false;
          }
  
       }
    const paginaCliente = ()=>{
    window.open("/?ruta=cliente")
    }
    const agregarProducto = () => {
        var selectProducto = document.getElementById("producto")
        var idProducto =selectProducto.value
        if(idProducto=="")
        return false;
        var valorProducto = selectProducto.options[selectProducto.selectedIndex].text;
        // revisamos si ya existe
        var found = productosservicios.find(pro =>{
            return Number(pro.producto_id)===Number(idProducto) 
        } );
        // se encontro
        if(found){
            alert("Producto repetido")
            return false;
        }
        var arregloServicios = [];
        [...document.getElementsByClassName("check-servicios")].forEach(
            (element, index, array) => {
                if(element.checked){
                arregloServicios.push(element.value);
                }
            }
        );
        if(arregloServicios.length==0){
            alert("Favor de seleccionar un servicio")
            return false;    
        }
        const clsProducto = {
            producto_id: idProducto,
            valor: valorProducto,
            servicios: arregloServicios,
        };
        setProductosservicios(productose => [...productose, clsProducto]);
        console.log(productosservicios)

    };
    const removerProducto = async (indexx,id) => {
        if(id){   
            const respuesta = await  swal({
                title: "¿estas seguro?",
                text: "¿Deseas eliminar esté registro(esté registro se eliminara de inmediato)?",
                icon: "warning",
                // buttons: true,
                buttons: ["Cancelar", "Eliminar"],
                dangerMode: true,
              })
              if(respuesta){
                const response =  await API.delete(URLPOLIZAEQUIPOELIMINAR+id+"/destroy");
                if(response.status===200){
                    swal("Equipo eliminado correctamente", {
                        icon: "success",
                    });
                    setProductosservicios(productose => [...productose.filter((producto, index) =>     (   indexx !==index     ))]);
                }else{
                    swal("Al parecer hubo un problema al eliminar, intente mas tarde", {
                        icon: "warning",
                    });
                }
    
              }
        }
        else
        setProductosservicios(productose => [...productose.filter((producto, index) =>     (   indexx !==index     ))]);
    };
    const actualizarEquipos = () => {
        if(equiposeleccionado.servicios.length==0){
            alert("Favor de seleccionar un servicio")
            return false;    
        }
        console.log(document.getElementById("fechaServicioModal").value)
        equiposeleccionado.fecha=document.getElementById("fechaServicioModal").value
        var elementosNuevos = productosservicios.map((prducto, index) => {
            if (prducto.id==equiposeleccionado.id) {
              return equiposeleccionado;
            }
          });
          setProductosservicios(elementosNuevos);
          document.getElementById("modalEquipoServiciosCerrar").click()
    };
    const cambiarcheck = (data) =>{
        var encontrato = false;
        var data = Number(data)
        if(equiposeleccionado.servicios){
            var encontrato = equiposeleccionado.servicios.find(id =>id === data );
        }else{
            encontrato = false;
        }
        if(encontrato){
         var serviciosCollecion =    equiposeleccionado.servicios.filter((item) =>{
            return item !== data
         } )
        }else{
        var serviciosCollecion =  [...equiposeleccionado.servicios,data]
        
        }
        setEquipoSeleccionado({...equiposeleccionado,servicios:serviciosCollecion })
    }
    return (
    <>
    <Titulo titulo="Pólizas" tituloBajo="Pólizas &gt; Editar"></Titulo>
    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Pólizas</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                <form onSubmit={handleSubmit(onSubmit)} id="form-poliza">
                        <h3>
                            Asignar póliza al cliente
                            <br />
                            <small>Selecciona los productos al igual que los servicios que tendrá dicho producto</small>
                        </h3>
                        <div className="form-group form-row">
                            <div className="col-6" >
                                <label htmlFor="catsucursal_id">Cliente*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" readOnly={true} value={cliente.nombre} name="cliente" id="cliente"/>
                                    {/* <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#modalCliente" type="button">Buscar</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <h3>
                            Seleccione los productos del cliente que cubrirá con esta póliza
                            <br />
                            <small>Selecciona el cliente o dalo de alta en caso de que aún no exista en la base de datos</small>
                        </h3>
                        <div className="form-group form-row">
                            
                            <div className="col" >
                                <label>Productos seleccionados</label>
                                <ul className="list-group">
                                    {
                                        productosservicios.map((objeto,index)=> {
                                                var texto = objeto.fecha ? "-"+objeto.fecha :"";
                                                texto =`${objeto.valor} (${objeto.servicios.length})  ${texto}`; 
                                         return ( 
                                             <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                {texto}
                                                <div>
                                                    {
                                                        objeto.id?
                                                        <span className="badge badge-pill" onClick={()=>{setEquipoSeleccionado({...objeto,fecha: new Date(objeto.fecha.replace(/-/g, '\/')) })}} data-toggle="modal" data-target="#modalEquipoServicios" ><i className="demo-icon icon-ver color-tecno">&#xe814;</i></span>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </li>)
                                        })
                                    }
                                </ul>
                            </div>

                        </div>    
                        <h3>
                            Periodo e inicio de servicio
                            <br />
                            <small>Define el periodo que deberá pasar entre cada visita de servicio y desde que fecha se realizara el primer servicio de esta póliza</small>
                        </h3>  
                        <div className="form-group form-row">
                            <div className="col" >
                                <label htmlFor="Periodo">Periodo*</label>
                                <select class="form-select" className="form-control" id="Periodo" name="Periodo" ref={register({ required: true })}>
                                    <option value="1">1 mes</option>
                                    <option value="2">2 meses</option>
                                    <option value="3">3 meses</option>
                                    <option value="4">4 meses</option>
                                    <option value="6">6 meses</option>
                                    <option value="12">12 meses</option>
                                </select>
                                {errors.Periodo &&
                                <div className="error-form text-red p-small">
                                   Este campo no puede estar vacío
                                </div>
                                }
                            </div>    
                            <div className="col" >
                            <label htmlFor="FechaPrimerServicio">Fecha* </label>
                                <DatePicker
                                            selected={fechaServicio}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            name="fechaServicio"
                                            id="fechaServicio"
                                            onChange={date=>setFechaServicio(date)}
                                                />       
                            </div>
                        </div>  
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Nota">Nota</label>
                                <input type="text" className="form-control" id="Nota" name="Nota"  ref={register}  />
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/polizas" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div className="modal fade" id="modalEquipoServicios" tabIndex="-1" role="dialog" aria-labelledby="modalEquipoServicios}CenterTitle" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title">Actualizar equipo en póliza
            {/* <button onClick={()=>{click()}} className="btn btn-primary">Agregar cliente</button > */}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <div className="form-row" >
                {
                    equiposeleccionado.fecha ?
                    <>
                        <div className="col-12" >
                            <label htmlFor="FechaPrimerServicio">Fecha*</label>
                                <DatePicker
                                            selected={new Date(equiposeleccionado.fecha)}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            name="fechaServicioModal"
                                            id="fechaServicioModal"
                                            onChange={date=>{
                                                var objeto = {};
                                                objeto =  equiposeleccionado;
                                                objeto.fecha = date;
                                                setEquipoSeleccionado({...equiposeleccionado,fecha:date.toString()});
                                            }
                                            }
                                                />      
                        </div>
                        <div className="col-12 form-row" >
                        {
                            servicios.length >0 ?
                            servicios.map((object, index) => 
                                {
                                 var arreglo = equiposeleccionado.servicios?equiposeleccionado.servicios:[];
                                 var  result = arreglo.find(id => id === object.id);
                                 return (
                                 <div className="form-check col-6 p-3" key={index}>
                                    <input className="form-check-input check-servicios-modal" checked={result} type="checkbox" value={object.id} id={`serviciciocheck-modal${object.id}`}  onChange={ (e)=>{ cambiarcheck(e.target.value) } }   />
                                    <label className="form-check-label" for={`serviciciocheck-modal${object.id}`}   >
                                        {object.Nombre}
                                    </label>
                                </div>)
                                }
                                )
                            :
                            ""
                        }
                        </div>
                    </>
                    :
                    ""

                }

            </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary cerrar-modal"  data-dismiss="modal" id="modalEquipoServiciosCerrar">Cerrar</button>
        </div>
        </div>
    </div>
    </div>
    </>

    )
}

export default VerPoliza;