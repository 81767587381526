import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
// ModalTablaEquipos
import ModalTablaEquipos from '../../../componentes/ModalTablaEquipos'
import object from './../../Usuarios/perfil.png';
const AgregarCliente =() => {
    const { register, handleSubmit, errors  } = useForm()
    const [equipocoleccion,setEquipocoleccion] = useState({id:null,nombre:null});
    const [equipose,setEquipose] = useState([])
    const params= new URLSearchParams(window.location.search)
    const onSubmit =async (data,e) => { 
        try
        {
            document.getElementById("enviar").disabled = true;
            let formData = new FormData(document.getElementById("formulario"));
            equipose.forEach((element)=>(formData.append("equipos[]", JSON.stringify(element))))
            const response =  await API.post('auth/catalogos/clientes-agregar', formData);
            if(response.status===201){
               swal({
                    title: "Registrado",
                    text: "Cliente agregado correctamente",
                    icon: "success",
                    button: "Ok"
               });

               if(params.get('clo')==="1"){
                setTimeout(() => {
                    window.close();
                }, 500);
                }else{
                    document.getElementById("enviar").disabled = false;
                    history.push('/clientes')
                }

            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
        } catch (error) {
        funciones.tratarerrores(error,document.getElementById("respuesta"));
        if(document.getElementById("enviar"))
            document.getElementById("enviar").disabled = false;
    }
    }
    useEffect(
        ()=>{
        }
    ,[]);
    const onChangeImg =  e => {
        // setValue("img", e.target.files[0]);
          // Creamos el objeto de la clase FileReader
        let reader = new FileReader();
        // Leemos el archivo subido y se lo pasamos a nuestro fileReader
        reader.readAsDataURL(e.target.files[0]);
        // Le decimos que cuando este listo ejecute el código interno
        reader.onload = ()=>{
            let img = document.getElementById('img-carga');
            img.src = reader.result;
        };
    }
    const agregarEquipo = () => {
        var idequipo =equipocoleccion.id
        
        if(!idequipo)
        return false;
        
        if(idequipo=="")
        return false;
        var nombreEquipo = equipocoleccion.nombre;
        // revisamos si ya existe
        var found = equipose.find(equipo =>{
            return equipo.equipo_id===idequipo
        } );
        // se encontro
        if(found){
            alert("Equipo ya repetido")
            return false;
        }
        const clsenfemero = {
            id: null,
            equipo_id: idequipo,
            valor: nombreEquipo,
        };
        setEquipose(equipose => [...equipose, clsenfemero]);
    };
    const removerEquipo = async (indexx,id) => {
        setEquipose(equipose => [...equipose.filter((equipos, index) =>     (   indexx !==index     ))]);
    };
    return (
    <>
    <Titulo titulo="Cliente" tituloBajo="Clientes &gt; Agregar Cliente"></Titulo>

    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3>Agregar cliente</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)} id="formulario">
                        <div className="form-group">
                            <div className="col-4 mx-auto text-center" >
                                <img src={object} id="img-carga" alt="Avatar" style={{"borderRadius":"50%","height":"136px"}} /> 
                                <input type="file" className="form-control" onChange={onChangeImg} id="imgD" name="imgD" ref={register()} accept="image/png,image/jpeg"  />
                            </div>
                        </div>
                        <h3>Datos empresa</h3>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Empresa">Cliente/Empresa*</label>
                                <input type="text" className="form-control" id="Empresa" name="Empresa" ref={register({ required: true })}  />
                                {errors.Empresa &&
                                <div className="error-form text-red p-small">
                                   Este campo no puede estar vacío
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Link">Link</label>
                                <input type="text" className="form-control" id="Link" name="Link"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Descripcion">Descripción</label>
                                <input type="text" className="form-control" id="Descripcion" name="Descripcion"  ref={register}  />
                                {errors.Descripcion &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <h3>Datos contacto</h3>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Contacto">Contacto</label>
                                <input type="text" className="form-control" id="Contacto" name="Contacto"  ref={register({ required: true })}  />
                                {errors.Contacto &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Estado">Estado</label>
                                <input type="text" className="form-control" id="Estado" name="Estado"  ref={register({ required: true })}  />
                                {errors.Estado &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Ciudad">Ciudad</label>
                                <input type="text" className="form-control" id="Ciudad" name="Ciudad"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Celular1">Celular #1*</label>
                                <input type="text" className="form-control" id="Celular1" name="Celular1"  ref={register({ required: true })}  />
                                {errors.Celular1 &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Celular2">Celular #2</label>
                                <input type="text" className="form-control" id="Celular2" name="Celular2"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Correo1">Correo #1*</label>
                                <input type="email" className="form-control" id="Correo1" name="Correo1"  ref={register({ required: true })}  />
                                {errors.Correo1 &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Correo2">Correo #2</label>
                                <input type="email" className="form-control" id="Correo2" name="Correo2"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Direccion">Dirección*</label>
                                <input type="text" className="form-control" id="Direccion" name="Direccion"  ref={register({ required: true })}  />
                                {errors.Direccion &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Comentario">Comentario</label>
                                <input type="text" className="form-control" id="Comentario" name="Comentario"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label htmlFor="catsucursal_id">Equipo*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" readOnly={true} value={equipocoleccion.nombre} name="equipocoleccion" id="equipocoleccion"/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#modalEquipo" type="button">Buscar</button>
                                    </div>
                                    <div className="input-group-append pl-2">
                                        <button className="btn btn-outline-secondary" type="button" onClick={()=>{agregarEquipo()}} >+</button>
                                    </div>
                                </div>
                                <ul className="list-group">
                                    {
                                        equipose.map((object,index)=>{
                                            const tieneId=false;
                                            const fieldName = `equipo_id[${index}]`;
                                            return (
                                            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                {object.valor}
                                                <span className="badge badge-primary badge-pill" onClick={
                                                    ()=>{
                                                    removerEquipo(index)
                                                    }
                                                    } >x</span>
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/clientes" className="btn btn-secondary">Regresar</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <ModalTablaEquipos titulo="Equipos" urlBusqueda="auth/catalogos/equipos-sinpaginacion" elegirElemento={setEquipocoleccion} idModal="modalEquipo" />
    </>

    )
}

export default AgregarCliente;