import React, {useEffect} from 'react';
import {Link} from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatos from '../../../librerias/obtenerDatos'
import Busqueda from '../../../componentes/Busqueda'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png'; 
import swal from 'sweetalert'

const Ordenes =() => {
    const URLORDEN= 'auth/reportes/ordenes'
    const ORDENELIMINAR = '/destroy'
    const { cargando,data:ordenes, paginas,cambiarPagina, buscarData, current  } =obtenerDatos(URLORDEN);
    const permisos  =obtenerPermisos("modulo-ordenes");

    const eliminar = async (id)=>{

        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await  swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          if(respuesta){
              try {
                const response =  await API.delete(URLORDEN+'/'+id+ORDENELIMINAR);
                if(response.status===200){
                    swal("Orden de servicio elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page=>page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error,document.getElementById("respuesta"));
            }

          }

    }

    const generarPDF = (id)=>{
        var tokenBarer = "";
        if(localStorage.getItem('user')){
            var usertoke = JSON.parse(localStorage.getItem('user'));
            var tokenBarer = "Bearer "+usertoke.access_token;
        }else{
            alert('Ocurrió un error');
            return true;
        }
        //http://localhost:8089/bgl-basculas-crm-servicios-mantenimiento/reportes/bglcontenido.php?idCliente=${object.id}
        var form = document.createElement('form');
        form.style.display="none"
        form.innerHTML = '<input name="token" value="'+tokenBarer+'">';
        form.innerHTML += '<input name="idOrden" value="'+id+'">';
        //form.action="http://localhost:8089/bgl-basculas-crm-servicios-mantenimiento/reportes/bglformato.php";
        form.action="https://bglbasculas.versionuno.uno/reportes/bglformato.php";
        form.method="POST";
        form.target="_blank";
        document.body.append(form);
        form.submit();
        form.remove();
    }

    const mostrarPDF = (id)=>{
        window.open('https://bglbasculas.versionuno.uno/reportes/pdfs/ordenes/'+(id+10000)+'/orden'+(id+10000)+'.pdf', '_blank');
        //window.open('http://localhost:8089/bgl-basculas-crm-servicios-mantenimiento/reportes/pdfs/ordenes/'+(id+10000)+'/orden'+(id+10000)+'.pdf', '_blank');
    }
    
    const irFirmar = (id)=>{
        var tokenBarer = "";
        if(localStorage.getItem('user')){
            var usertoke = JSON.parse(localStorage.getItem('user'));
            var tokenBarer = "Bearer "+usertoke.access_token;
        }else{
            alert('Ocurrió un error');
            return true;
        }
        var form = document.createElement('form');
        form.style.display="none"
        form.innerHTML = '<input name="token" value="'+tokenBarer+'">';
        form.innerHTML += '<input name="idOrden" value="'+id+'">';
        //form.action="http://localhost:8089/bgl-basculas-crm-servicios-mantenimiento/reportes/firmar-orden.php";
        form.action="https://bglbasculas.versionuno.uno/reportes/firmar-orden.php";
        form.method="POST";
        document.body.append(form);
        form.submit();
        form.remove();
    }

    return (
        <>
        <Titulo titulo="Ordenes de servicio" tituloBajo=""></Titulo>
    
        <div className="row">
            <div className="col-xl-12">
                <div className="widget has-shadow">
                    <div className="widget-header bordered no-actions d-flex align-items-center">
                        <h3 className="pl-3">Ordenes de servicio</h3>
                        <Busqueda buscarData={buscarData} icono={icono} permiso={permisos} ruta="/AgregarOrden" ></Busqueda>
                    </div>
                    <div className="pb-3">
                        <div className="table-responsive">
                        { cargando ?
                            <div className="text-center pt-5">
                                <h3 style={{color: "black"}}>Cargando...</h3>
                            </div>
                            :
                            <>
                            {
                                ordenes.length >0 ?
                                <>
                                <table className="table mb-0 table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">Folio</th>
                                            <th scope="col">Cliente</th>
                                            <th scope="col">Fecha Creación</th>
                                            <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        ordenes.map((object, index) => (
                                        <tr key={index}>
                                            <td>{ object.id + 10000 }</td>
                                            <td>{ object.Empresa }</td>
                                            <td>{ object.created_at }</td>
                                            <td>
                                                {
                                                permisos.Actualizar ===1  ?
                                                    object.firma_trabajador === '' ?
                                                    <Link onClick={()=>irFirmar(object.id)} name="id" to={"#"}><i title="Firmar orden de servio" className="demo-icon fa fa-pen-alt color-tecno"></i></Link>
                                                    :
                                                    object.firma_cliente === '' ?
                                                    <Link onClick={()=>irFirmar(object.id)} name="id" to={"#"}><i title="Firmar orden de servio" className="demo-icon fa fa-pen-alt color-tecno"></i></Link>
                                                    :
                                                    <span><i className="demo-icon fa pen-alt color-tecno"></i></span>
                                                :
                                                <span><i className="demo-icon fa pen-alt color-tecno"></i></span>
                                                }
                                                {
                                                permisos.Visualizar ===1  ?
                                                    object.definitivo ===0  ?
                                                    <Link className="ml-3" onClick={()=>generarPDF(object.id)} to="#" name="id"><i title="Ver PDF" className="demo-icon fa fa-file-pdf color-tecno"></i></Link>
                                                    :
                                                    <Link className="ml-3" onClick={()=>mostrarPDF(object.id)} to="#" name="id"><i title="Ver PDF" className="demo-icon fa fa-file-pdf color-tecno"></i></Link>
                                                :
                                                ""
                                                }
                                                {
                                                permisos.Eliminar ===1  ?
                                                <Link className="ml-3" to="#" onClick={()=>eliminar(object.id)}><i title="Eliminar orden" className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link> 
                                                :
                                                ""
                                                }
                                            </td>
                                        </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                                <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                                </>
                                :
                                <div className="text-center pt-5">
                                    <h3>Sin resultados...</h3>
                                </div> 
                            }
                            </>
                        }
                        </div>
                    </div>
                    <div id="respuesta" ></div>
                </div>
            </div>
        </div>
        </>
    
        )

}

export default Ordenes;