import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatosFecha from '../../../librerias/obtenerDatosFecha'
import BusquedaFecha from '../../../componentes/BusquedaFecha'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png'; 
import swal from 'sweetalert'


const ProximasPolizas =() => {
    const POLIZASURL= 'auth/servicio/polizas-calendario'
    const POLIZAELIMINAR = '/destroy'
    const { cargando,data:polizas, paginas,cambiarPagina, buscarData, current  } =obtenerDatosFecha(POLIZASURL);
    const permisos  =obtenerPermisos("modulo-poliza");


    const eliminar = async (id)=>{

        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await  swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          if(respuesta){
              try {
                const response =  await API.delete('auth/servicio/polizas'+'/'+id+POLIZAELIMINAR);
                if(response.status===200){
                    swal("Poliza elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page=>page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error,document.getElementById("respuesta"));
            }

          }

    }

    const generarPeriodo =(num)=>{
        let periodo = '';
        switch(num){
            case '1':
                periodo = 'Mensual';
                break;
            case '2':
                periodo = 'Bimestral';
                break;
            case '3':
                periodo = 'Trimestral';
                break;
            case '4':
                periodo = 'Cuatrimestral';
                break;
            case '6':
                periodo = 'Semestral';
                break;
            case '12':
                periodo = 'Anual';
                break;
        }
        return periodo;
    }

    const obtenerDia =(fecha)=>{
        let dia = fecha.substr(-2,2);
        return dia;
    }

    const getMes =(num)=>{
        let mes = '';
        switch(num){
            case 1:
               mes = 'Ene.';
                break;
            case 2:
                mes = 'Feb.';
                break;
            case 3:
                mes = 'Mar.';
                break;
            case 4:
                mes = 'Abr.';
                break;
            case 5:
                mes = 'May.';
                break;
            case 6:
                mes = 'Jun.';
                break;
            case 7:
                mes = 'Jul.';
                break;
            case 8:
                mes = 'Ago.';
                break;
            case 9:
                mes = 'Sep.';
                break;
            case 10:
                mes = 'Oct.';
                break;
            case 11:
                mes = 'Nov.';
                break;
            case 12:
                mes = 'Dic.';
                break;
            default:
                mes = 'Ene.';
        }
        return mes;
    } 

    return (
    <>
    <Titulo titulo="Próximas pólizas" tituloBajo=""></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Pólizas</h3>
                    
                    <BusquedaFecha buscarData={buscarData} icono={icono} permiso={permisos} ruta="/Agregarpoliza" ></BusquedaFecha>
                </div>
                <div className="pb-3">
                    <div className="table-responsive">
                    { cargando ?
                        <div className="text-center pt-5">
                            <h3 style={{color: "black"}}>Cargando...</h3>
                        </div>
                        :
                        <>
                        {
                            polizas.length >0 ?
                            <>
                            <table className="table mb-0 table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Folio</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Nota</th>
                                        <th scope="col">Periodo</th>
                                        <th scope="col">#Equipos</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    polizas.map((object, index) => (
                                    <tr key={index}>
                                        <td>{ object.folio }</td>
                                        <td>{ object.Empresa }</td>
                                        <td>{ object.Nota }</td>
                                        <td>{ generarPeriodo(object.Periodo) }</td>
                                        <td>{ object.equipo_count }</td>
                                        <td>{ getMes(object.mes)+' '+obtenerDia(object.fechaServicio+'') }</td>
                                        <td>
                                            {
                                            permisos.Actualizar ===1  ?
                                            <Link className="" name="id" to={`/editar-poliza/${object.id}`}><i className="demo-icon icon-editar color-tecno">&#xe804;</i></Link>
                                            :
                                            ""
                                            }
                                            {
                                            permisos.Visualizar ===1  ?
                                            <Link className="ml-3" name="id" to={`/ver-poliza/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                            :
                                            ""
                                            }
                                            {
                                            permisos.Eliminar ===1  ?
                                            <Link className="ml-3" to="#" onClick={()=>eliminar(object.id)}><i className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link> 
                                            :
                                            ""
                                            }
                                        </td>
                                    </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                            </>
                            :
                            <div className="text-center pt-5">
                                <h3>Sin resultados...</h3>
                            </div> 
                        }
                        </>
                    }
                    </div>
                </div>
                <div id="respuesta" ></div>
            </div>
        </div>
    </div>
    </>

    )
}

export default ProximasPolizas;