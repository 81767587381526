import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";

const Verservicio =() => {
    const URLSERVICIOLEER = 'auth/catalogos/servicios-mostrar/'
    let { id } = useParams();
    const servicio  =obtenerData(URLSERVICIOLEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(servicio) =>{
            setValue('Nombre', servicio.Nombre)
            setValue('Descripcion', servicio.Descripcion)
            }
        asignar(servicio)
        }
    ,[setValue,servicio]);

    return (
    <>
    <Titulo titulo="Servicios" tituloBajo="Servicios &gt; Editar servicio"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Servicios</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label for="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label for="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/servicios" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default Verservicio;