import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";

const Vermarca =() => {
    const URLMARCASLEER = 'auth/catalogos/marcas-mostrar/'
    let { id } = useParams();
    const marca  =obtenerData(URLMARCASLEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()

    useEffect(
        ()=>{
        const asignar =(marca) =>{
            setValue('Nombre', marca.Nombre)
            setValue('Descripcion', marca.Descripcion)
            }
        asignar(marca)
        }
    ,[setValue,marca]);
    
    
    return (
    <>
    <Titulo titulo="Marcas" tituloBajo="Marcas &gt; Editar"></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Marcas</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form >
                        <div className="form-group">
                            <label for="Nombre">Nombre*</label>
                            <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                            {errors.Nombre &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div className="form-group">
                            <label for="Descripcion">Descripción*</label>
                            <textarea className="form-control" id="Descripcion" name="Descripcion" rows="3" ref={register({ required: true })}></textarea>
                            {errors.Descripcion &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/marcas" className="btn btn-secondary">Atras</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default Vermarca;