import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    Link  } from "react-router-dom";
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import object from './../../Usuarios/perfil.png';
const VerCliente =() => {
    const URLCLIENTELEER = 'auth/catalogos/clientes-mostrar/'
    let { id } = useParams();
    const cliente  =obtenerData(URLCLIENTELEER,id,1)
    const { register, handleSubmit, errors,setValue,control  } = useForm()
    const [equipose,setEquipose] = useState([])

    useEffect(
        ()=>{
        const asignar =(cliente) =>{
            setValue('Empresa', cliente.Empresa)
            setValue('Descripcion', cliente.Descripcion)
            setValue('Link', cliente.Link)
            setValue('Contacto', cliente.Contacto)
            setValue('Celular1', cliente.Celular1)
            setValue('Celular2', cliente.Celular2)
            setValue('Correo1', cliente.Correo1)
            setValue('Correo2', cliente.Correo2)
            setValue('Direccion', cliente.Direccion)
            setValue('Comentario', cliente.Comentario)
            setValue('Correo2', cliente.Correo2)
            setValue('Estado', cliente.Estado)
            setValue('Ciudad', cliente.Ciudad)
            document.getElementById("img-carga").src=cliente.img+"?v="+Math.random();
            var equipoArray = [];
            var equipoIndice = 0;
            if(cliente.equipo)
            cliente.equipo.forEach(element=>{
                equipoArray[equipoIndice++] ={
                    id:element.id,
                    equipo_id: element.equipo_id,
                    valor: element.equipo.Nombre,
                }
            })
            setEquipose(equipoArray);
            }
        asignar(cliente)
        
        }
    ,[cliente]);
    

  
    const errorImg = e =>{
        e.target.src=object;
    }
    return (
    <>
    <Titulo titulo="Cliente" tituloBajo="Clientes &gt; Editar Cliente"></Titulo>

    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3>Agregar cliente</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form  id="formulariod">
                        <div className="form-group">
                            <div className="col-4 mx-auto text-center" >
                                <img src={object}  onError={errorImg} id="img-carga" alt="Avatar" style={{"borderRadius":"50%","height":"136px"}} /> 
                            </div>
                        </div>
                        <h3>Datos empresa</h3>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Empresa">Cliente/Empresa*</label>
                                <input type="text" className="form-control" id="Empresa" name="Empresa" ref={register({ required: true })}  />
                                {errors.Empresa &&
                                <div className="error-form text-red p-small">
                                   Este campo no puede estar vacío
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Link">Link</label>
                                <input type="text" className="form-control" id="Link" name="Link"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Descripcion">Descripción</label>
                                <input type="text" className="form-control" id="Descripcion" name="Descripcion"  ref={register}  />
                                {errors.Descripcion &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <h3>Datos contacto</h3>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Contacto">Contacto</label>
                                <input type="text" className="form-control" id="Contacto" name="Contacto"  ref={register({ required: true })}  />
                                {errors.Contacto &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Estado">Estado</label>
                                <input type="text" className="form-control" id="Estado" name="Estado"  ref={register({ required: true })}  />
                                {errors.Estado &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Ciudad">Ciudad</label>
                                <input type="text" className="form-control" id="Ciudad" name="Ciudad"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Celular1">Celular #1*</label>
                                <input type="text" className="form-control" id="Celular1" name="Celular1"  ref={register({ required: true })}  />
                                {errors.Celular1 &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Celular2">Celular #2</label>
                                <input type="text" className="form-control" id="Celular2" name="Celular2"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Correo1">Correo #1*</label>
                                <input type="email" className="form-control" id="Correo1" name="Correo1"  ref={register({ required: true })}  />
                                {errors.Correo1 &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col" >
                                <label htmlFor="Correo2">Correo #2</label>
                                <input type="email" className="form-control" id="Correo2" name="Correo2"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Direccion">Dirección*</label>
                                <input type="text" className="form-control" id="Direccion" name="Direccion"  ref={register({ required: true })}  />
                                {errors.Direccion &&
                                <div className="error-form text-red p-small">
                                    Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col" >
                                <label htmlFor="Comentario">Comentario</label>
                                <input type="text" className="form-control" id="Comentario" name="Comentario"  ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label htmlFor="catsucursal_id">Equipo*</label>
                                                                <ul className="list-group">
                                    {
                                        equipose.map((object,index)=>{
                                            const tieneId=false;
                                            const fieldName = `equipo_id[${index}]`;
                                            return (
                                            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                {object.valor}
                                                
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/clientes" className="btn btn-secondary">Regresar</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default VerCliente;